import React from 'react';
import {Switch} from "react-native";
import styled from "styled-components/native";
import {secundaryColorLight} from "../styles/variables";
import {State} from "../store/reducers";
import {connect} from "react-redux";
import {currentPlatform} from "../util/platform";

const SwitchElement = (props: any) => {

    const {onChange, label, checked, primaryColor, ...p} = props

    const styles = currentPlatform.isWeb() ? {
        activeTrackColor: secundaryColorLight,
    } : {
        trackColor: {
            true: secundaryColorLight,
        }
    }

    return (
        <SwitchContainer>
            <Label>{label}</Label>
            <Switch {...p} value={checked} {...styles} thumbColor={checked ? primaryColor : '#fff'} onValueChange={(e) => {
                onChange(e)
            }}/>
        </SwitchContainer>
    );
};

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state.configuracao.configuracao.idWebTemplate.cor,
    }
}

export default connect(mapStateToProps, null)(SwitchElement);

const SwitchContainer = styled.View`
width: 100%;
flex-direction: row;
justify-content: space-between;
`

const Label = styled.Text`
color: #86939e;
font-weight: bold;
font-size: 16px;
`