import React from 'react';
import {fromHsv, HsvColor, TriangleColorPicker} from 'react-native-color-picker'
import styled from "styled-components/native";
import {secundaryColor} from "../../styles/variables";
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from "react-native-responsive-screen";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {configuracaoCreators} from "../../store/reducers/configuracao_reducer";
import ButtonElement from "../../components/ButtonElement";
import Configuracao from "../../models/configuracao.model";
import MaxWidth from "../../layout/MaxWidth";

const DesignScreen = ({changePrimaryColor, primaryColor, save}) => {

    const colorChange = (color: HsvColor) => {
        changePrimaryColor(fromHsv(color))
    }

    const salvarCor = () => {
        save({idWebTemplate: {cor: primaryColor}})
    }

    return (
        <MaxWidth>
            <Page>
                <LabelText>Escolha a cor principal</LabelText>
                {
                    // @ts-ignore
                    <TriangleColorPicker
                        style={{width: wp('80%'), height: hp('70%')}}
                        onColorChange={colorChange} hideSliders={true} defaultColor={primaryColor}/>
                }
                <ButtonContainer>
                    <ButtonElement title={'Salvar'} containerStyle={{width: 600}} onPress={salvarCor}/>
                </ButtonContainer>
            </Page>
        </MaxWidth>
    );
};

DesignScreen.routeName = 'design'

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    changePrimaryColor: (primaryColor: string) => dispatch(configuracaoCreators.changePrimaryColor(primaryColor)),
    save: (configuracao: Configuracao) => dispatch(configuracaoCreators.salvarConfiguracao(configuracao)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesignScreen);


const Page = styled.View`
flex: 1;
justify-content: space-around;
align-items: center;
`

const LabelText = styled.Text`
margin-left: 10px;
margin-top: 40px;
color: ${secundaryColor};
font-size: 26px;
`

const ButtonContainer = styled.View`
width: 800px;
height: 80px;
background-color: white;
position: absolute;
bottom: 0;
justify-content: center;
align-items: center;
flex-direction: row;
`
