import React, {useEffect} from 'react';
import styled from "styled-components/native";
import {primaryColorDefault} from "../../styles/variables";
import {Controller, useForm} from "react-hook-form";
import {connect} from "react-redux";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import InputElement from "../../components/InputElement";
import {Button} from "react-native-elements";
import {AntDesign} from '@expo/vector-icons';
import {widthPercentageToDP as wp} from 'react-native-responsive-screen';
import {usuarioCreators} from "../../store/reducers/usuario_reducer";
import {Picker} from "react-native";
import {currentPlatform} from "../../util/platform";
import RegisterScreen from "./RegisterScreen";
import {useMediaQuery} from 'react-responsive'
import MaxWidth from "../../layout/MaxWidth";

const LoginScreen = ({logar, navigation}) => {

    const {control, handleSubmit, reset}: any = useForm()
    const onSubmit = data => {
            logar(data)
    }

    const isWebDesktop = useMediaQuery({
        maxDeviceWidth: 800,
    })



    return (
        <MaxWidth>
            <Page>
                <Controller control={control} label={'Usuario'} as={InputElement} name="usuario" defaultValue={''}
                            leftIcon={<AntDesign name="user" size={24} color="white"/>} whiteColor={true}/>
                <Controller control={control} label={'Senha'} as={InputElement} secureTextEntry={true} name="senha" defaultValue={''}
                            leftIcon={<AntDesign name="lock" size={24} color="white"/>} whiteColor={true}/>
                <Button title={'ENTRAR'} onPress={handleSubmit(onSubmit)} containerStyle={{width: 400}}/>
                <TextCadastrar onPress={() => navigation.push(RegisterScreen.routeName)}>Toque aqui para se cadastrar</TextCadastrar>
            </Page>
        </MaxWidth>
    )

};

LoginScreen.routeName = '/login'

const mapStateToProps = (state: State) => {
    return {
        empresas: state?.usuario?.empresas,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logar: (value) => dispatch(usuarioCreators.logarPrimeiraEtapa(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

const Page = styled.View`
flex: 1;
justify-content: center;
align-items: center;
background-color: ${primaryColorDefault};
`

const TextCadastrar = styled.Text`
color: #fff;
text-decoration: underline;
margin-top: 10px;
`
