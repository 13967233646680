import React from 'react';
import {Modal, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import styled from "styled-components/native";
import ModalWeb from 'modal-enhanced-react-native-web';
import {Controller, useForm} from "react-hook-form";
import InputElement from "../../components/InputElement";
import {colorRed, primaryColorDefault} from "../../styles/variables";

const CommomContent = ({closeModal, salvar}) => {

    const {control, handleSubmit, reset}: any = useForm()

    const onSubmit = data => {
        salvar(data)
    }

    return (
        <DialogBody>
            <Controller control={control} label={'Nome'} as={InputElement} name="nome" defaultValue={''} />
            <Footer>
            <ButtonTouchable onPress={closeModal}><NegativeButton>Cancelar</NegativeButton></ButtonTouchable>
            <ButtonTouchable onPress={handleSubmit(onSubmit)}><PositiveButton>Salvar</PositiveButton></ButtonTouchable>
            </Footer>

        </DialogBody>
    )
}

export const ModalWebCategoria = (props) => {
    const {visible} = props
    return (
        <View>
            <ModalWeb isVisible={visible}
                // onBackdropPress={closeModal}
            >
                <CommomContent {...props} />
            </ModalWeb>
        </View>
    );
}

export const ModalMobileCategoria = (props) => {
    const {visible} = props
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={() => {
            }}
        >
            <View style={styles.background}>
                <TouchableOpacity onPress={() => console.log('teste')}>
                    <CommomContent {...props} />
                </TouchableOpacity>
            </View>
        </Modal>
    );
};

const DialogBody = styled.View`
background-color: #fff;
width: 80%;
margin-left: 10%;
padding: 20px;
`

const Footer = styled.View`
flex-direction: row;
justify-content: flex-end;
width: 100%;
margin-top: 20px;
`

const ButtonTouchable = styled.TouchableOpacity`
color: ${colorRed};
padding: 5px 10px;
`

const PositiveButton = styled.Text`
color: ${primaryColorDefault};
`

const NegativeButton = styled.Text`
color: ${colorRed};
margin-right: 10px;
`

const styles = StyleSheet.create({

    background: {
        justifyContent: "center",

        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
    },
})