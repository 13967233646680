import Action from "../../models/action.model";

export const USUARIO_TYPES = {
    LOGAR_PRIMEIRA_ETAPA: 'usuario/LOGAR_PRIMEIRA_ETAPA',
    LOGAR_PRIMEIRA_ETAPA_SUCCESS: 'usuario/LOGAR_PRIMEIRA_ETAPA_SUCCESS',
    LOGAR_PRIMEIRA_ETAPA_ERROR: 'usuario/LOGAR_PRIMEIRA_ETAPA_ERROR',

    LOGAR_SEGUNDA_ETAPA: 'usuario/LOGAR_SEGUNDA_ETAPA',
    LOGAR_SEGUNDA_ETAPA_SUCCESS: 'usuario/LOGAR_SEGUNDA_ETAPA_SUCCESS',
    LOGAR_SEGUNDA_ETAPA_ERROR: 'usuario/LOGAR_SEGUNDA_ETAPA_ERROR',

    LOGAR_TERCEIRA_ETAPA: 'usuario/LOGAR_TERCEIRA_ETAPA',
    LOGAR_TERCEIRA_ETAPA_SUCCESS: 'usuario/LOGAR_TERCEIRA_ETAPA_SUCCESS',
    LOGAR_TERCEIRA_ETAPA_ERROR: 'usuario/LOGAR_TERCEIRA_ETAPA_ERROR',

    LOGAR_QUARTA_ETAPA: 'usuario/LOGAR_QUARTA_ETAPA',
    LOGAR_QUARTA_ETAPA_SUCCESS: 'usuario/LOGAR_QUARTA_ETAPA_SUCCESS',
    LOGAR_QUARTA_ETAPA_ERROR: 'usuario/LOGAR_QUARTA_ETAPA_ERROR',

    SELECIONA_EMPRESA: 'usuario/SELECIONA_EMPRESA',
    LOGIN_COMPLETED: 'usuario/LOGIN_COMPLETED',
}

export interface UsuarioType {
    usuarioLoginProcess: any
    usuarioLogado: null
    empresas: any[]
    empresaSelecionada: any
    token: string
}

export const INITIAL_STATE: UsuarioType = {
    usuarioLoginProcess: null,
    usuarioLogado: null,
    empresas: [],
    empresaSelecionada: '',
    token: null,
}

export const usuarioCreators = {
    logarPrimeiraEtapa: (value: any) => ({type: USUARIO_TYPES.LOGAR_PRIMEIRA_ETAPA, payload: value}),
    logarPrimeiraEtapaSucesso: (value: any) => ({type: USUARIO_TYPES.LOGAR_PRIMEIRA_ETAPA_SUCCESS, payload: value}),
    logarSegundaEtapaSucesso: (value: any) => ({type: USUARIO_TYPES.LOGAR_SEGUNDA_ETAPA_SUCCESS, payload: value}),
    logarTerceiraEtapaSucesso: (value: any) => ({type: USUARIO_TYPES.LOGAR_TERCEIRA_ETAPA_SUCCESS, payload: value}),
    logarQuartaEtapa: (value: any) => ({type: USUARIO_TYPES.LOGAR_QUARTA_ETAPA, payload: value}),
    selecionaEmpresa: (value: any) => ({type: USUARIO_TYPES.SELECIONA_EMPRESA, payload: value}),
    loginCompleted: (value: any) => ({type: USUARIO_TYPES.LOGIN_COMPLETED, payload: value}),
}

export const usuario = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case USUARIO_TYPES.LOGAR_PRIMEIRA_ETAPA_SUCCESS:
            return {...state, usuarioLoginProcess: action.payload}
        case USUARIO_TYPES.LOGAR_SEGUNDA_ETAPA_SUCCESS:
            return {...state, usuarioLogado: action.payload}
        case USUARIO_TYPES.LOGAR_TERCEIRA_ETAPA_SUCCESS:
            return {...state, empresas: action.payload}
        case USUARIO_TYPES.SELECIONA_EMPRESA:
            return {...state, empresaSelecionada: action.payload}
        case USUARIO_TYPES.LOGIN_COMPLETED:
            return {...state, token: action.payload}
        default:
            return state
    }
}
