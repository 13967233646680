import React, {useEffect} from 'react';
import ImageUpload from "../../components/ImageUpload";
import styled from "styled-components/native";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import Produto from "../../models/produto.model";
import {connect} from "react-redux";
import {secundaryColor} from "../../styles/variables";
import {Button} from "react-native-elements";
import {AntDesign} from "@expo/vector-icons";
import {produtoCreators} from "../../store/reducers/produto_reducer";

const ProdutoCadastroStep3 = ({salvar, produto, primaryColor, uploadImage, route}) => {

    useEffect(() => {
        if(route.params?.base64Image) {
            handleImage(route.params.base64Image)
        }
    }, [route.params?.base64Image])

    const concluir = () => {
        salvar({...produto})
    }

    const handleImage = (base64: string) => {
        const data = {imagem: base64.includes('base64') ? base64 : 'data:image/png;base64,' + base64, nomeProduto: produto.nome + new Date().getTime()}
        console.log(data)

        uploadImage(data)
    }

    return (
        <Page>
            <LabelText>Se desejar, adicione uma foto ao seu produto</LabelText>
            <ImageContainer>
                <ImageUpload image={produto.foto} setImage={handleImage}/>
            </ImageContainer>
            <Button icon={<AntDesign name="checkcircle" size={18} color="white" style={{marginRight: 10}}/>}
                    buttonStyle={{backgroundColor: primaryColor}} title="Concluir" onPress={concluir}/>
        </Page>
    );
};

ProdutoCadastroStep3.routeName = 'produto-cadastro/step-3'

const mapStateToProps = (state: State) => {
    return {
        produto: state.produto.produto,
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    salvar: (produto: Produto) => dispatch(produtoCreators.produtoSalvar(produto)),
    uploadImage: (value: any) => dispatch(produtoCreators.uploadFoto(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoCadastroStep3);

const Page = styled.View`
flex: 1;
justify-content: space-between;
`

const LabelText = styled.Text`
margin-left: 10px;
margin-bottom: 60px;
margin-top: 80px;
color: ${secundaryColor};
font-size: 26px;
text-align: center;
`

const ImageContainer = styled.View`
flex: 1;
flex-direction: row;
align-items: center;
justify-content: center;
`
