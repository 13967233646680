import React, {useEffect} from 'react';
import BaseScreen from "../../layout/BaseScreen";
import Form from "../../layout/Form";
import {Controller, useForm} from "react-hook-form";
import {ScrollView, View} from "react-native";
import InputElement from "../../components/InputElement";
import {configuracao, configuracaoCreators} from "../../store/reducers/configuracao_reducer";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import Configuracao from "../../models/configuracao.model";
import {connect} from "react-redux";
import {widthPercentageToDP} from "react-native-responsive-screen";
import styled from "styled-components/native";
import ButtonElement from "../../components/ButtonElement";
import {colorRed} from "../../styles/variables";

const SecurityScreen = ({configuracao, salvar}) => {

    const {control, handleSubmit, reset}: any = useForm()

    useEffect(() => {
        reset({
            nomeLoja: configuracao?.nomeLoja,
            urlDominio: configuracao?.urlDominio,
        })
    }, [configuracao])

    const onSubmit = data => {
        salvar(data)
    }

    return (
        <Page>
            <TextContainer>
                <DangerTextTitle>Cuidado!</DangerTextTitle>
                <DangerText>alterar essas informacoes pode trazer serias consequencias!</DangerText>
            </TextContainer>
            <FormStyled>
                <Controller control={control} label={'Nome da loja'} as={InputElement} name="nomeLoja"
                            defaultValue={''}/>
                <Controller control={control} label={'Url do dominio'} as={InputElement} name="urlDominio"
                            defaultValue={''}/>
            </FormStyled>
            <View /><View /><View />
            <ButtonContainer>
                <SubmmitButton onPress={handleSubmit(onSubmit)} title={'Salvar'}/>
            </ButtonContainer>
        </Page>
    );
};

SecurityScreen.routeName = 'loja/security'

const mapStateToProps = (state: State) => {
    return {
        configuracao: state.configuracao.configuracao,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    salvar: (configuracao: Configuracao) => dispatch(configuracaoCreators.salvarConfiguracao(configuracao)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityScreen);

const SubmmitButton = styled(ButtonElement)`
margin: 20px 0;
width: 600px;
`

const Page = styled.View`
align-items: center;
justify-content: space-between;
height: 100%;
`

const FormStyled: any = styled(Form)`
justify-content: space-between;
`

const TextContainer = styled.View`
margin-top: 30px;
`

const DangerTextTitle = styled.Text`
color: ${colorRed};
font-weight: bold;
font-size: 18px;
text-align: center;
margin-bottom: 10px;
`

const DangerText = styled.Text`
color: ${colorRed};
font-size: 15px;
text-align: center;
`

const ButtonContainer = styled.View`
margin-bottom: 20px;
width: 100%;
max-width: 350px;
`
