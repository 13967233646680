import createSagaMiddleware from 'redux-saga'
import {applyMiddleware, createStore, compose} from "redux"
import rootReducer from "./reducers"
import rootSaga from "./sagas";
import Reactotron from '../config/ReactotronConfig'
import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from "@react-native-community/async-storage";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// @ts-ignore
const sagaMonitor = Reactotron.createSagaMonitor()
const sagaMiddleware = createSagaMiddleware({sagaMonitor})

const middlewares = [
    sagaMiddleware,
];

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['usuario', 'configuracao']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
    // @ts-ignore
    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares), Reactotron.createEnhancer()),)

    const persistor = persistStore(store)

    sagaMiddleware.run(rootSaga)

    return {store, persistor}
}

export default configureStore()
