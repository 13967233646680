import React from "react";
import {ThemeContext, getTheme} from 'react-native-material-ui';
import {State} from "../store/reducers";
import {connect} from "react-redux";

const MaterialUIConfig = ({children, primaryColor}) => {

    const uiTheme = {
        palette: {
            primaryColor: primaryColor,
        },
        toolbar: {
            container: {
                height: 50,
            },
        },
    };

    return (
        <ThemeContext.Provider value={getTheme(uiTheme)}>
            {children}
        </ThemeContext.Provider>
    );
};

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor || 'rgb(84, 156, 206)',
    }
}

export default connect(mapStateToProps, null)(MaterialUIConfig);