import React, {useEffect} from 'react';
import BaseScreen from "../../layout/BaseScreen";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import Configuracao from "../../models/configuracao.model";
import {configuracaoCreators} from "../../store/reducers/configuracao_reducer";
import {connect} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {ScrollView, View} from "react-native";
import Form from "../../layout/Form";
import styled from "styled-components/native";
import SwitchElement from "../../components/SwitchElement";
import InputElement from "../../components/InputElement";
import ButtonElement from "../../components/ButtonElement";
import {toNumber} from "../../util/math_util";
import InputMonetario from "../../components/InputMonetario";

const EntregaScreen = ({save, configuracao}) => {

    const {control, handleSubmit, reset}: any = useForm()

    const onSubmit = (data: Configuracao) => {
        save({
            ...data,
            valorTaxaEntrega: toNumber(data.valorTaxaEntrega),
            entregaGratisAcimaDe: toNumber(data.entregaGratisAcimaDe),
            descontoRetirarLocal: toNumber(data.descontoRetirarLocal),
            valorMinimoDelivery: toNumber(data.valorMinimoDelivery),
        })
    }

    useEffect(() => {
        reset({
            retirarNoLocal: configuracao?.retirarNoLocal,
            taxaEntrega: configuracao?.taxaEntrega,
            exigePedidoMinDelivery: configuracao?.exigePedidoMinDelivery,
            deliveryAtivo: configuracao?.deliveryAtivo,
            entregaGratis: configuracao?.entregaGratis,
            tempoEntrega: configuracao?.tempoEntrega,
            valorTaxaEntrega: configuracao?.valorTaxaEntrega,
            entregaGratisAcimaDe: configuracao?.entregaGratisAcimaDe,
            descontoRetirarLocal: configuracao?.descontoRetirarLocal,
            valorMinimoDelivery: configuracao?.valorMinimoDelivery,
        })
    }, [configuracao])

    return (
        <ScrollView>
            <BaseScreen>
                <Form>
                    <SwitchsContainer>
                        <Controller control={control} label={'Retirar no local'} as={SwitchElementComMargem} name="retirarNoLocal" defaultValue={false}/>
                        <Controller control={control} label={'Taxa entrega'} as={SwitchElementComMargem} name="taxaEntrega" defaultValue={false}/>
                        <Controller control={control} label={'Exige pedido min delivery'} as={SwitchElementComMargem} name="exigePedidoMinDelivery" defaultValue={false}/>
                        <Controller control={control} label={'Delivery ativo'} as={SwitchElementComMargem} name="deliveryAtivo" defaultValue={false}/>
                        <Controller control={control} label={'Entrega gratis'} as={SwitchElementComMargem} name="entregaGratis" defaultValue={false}/>
                    </SwitchsContainer>
                    <Controller control={control} label={'Tempo de entrega'} placeholder={'ex: 30 minutos'} as={InputElement} name="tempoEntrega" defaultValue={''}/>
                    <Controller control={control} label={'Valor da taxa de entrega'} as={InputMonetario} name="valorTaxaEntrega" defaultValue={''} placeholder={'0,00'} keyboardType={'numeric'}/>
                    <Controller control={control} label={'Entrega gratis a partir de'} as={InputMonetario} name="entregaGratisAcimaDe" defaultValue={''} placeholder={'0,00'} keyboardType={'numeric'}/>
                    <Controller control={control} label={'Desconto Retirar no Local'} as={InputMonetario} name="descontoRetirarLocal" defaultValue={''} placeholder={'0,00'} keyboardType={'numeric'}/>
                    <Controller control={control} label={'Valor minimo delivery'} as={InputMonetario} name="valorMinimoDelivery" defaultValue={''} placeholder={'0,00'} keyboardType={'numeric'}/>
                    <ButtonContainer>
                        <SubmmitButton onPress={handleSubmit(onSubmit)} title={'Salvar'} />
                    </ButtonContainer>
                </Form>
            </BaseScreen>
        </ScrollView>
    );
};

EntregaScreen.routeName = 'entrega'

const mapStateToProps = (state: State) => {
    return {
        configuracao: state.configuracao.configuracao,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    save: (configuracao: Configuracao) => dispatch(configuracaoCreators.salvarConfiguracao(configuracao)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EntregaScreen)

const SwitchsContainer: any = styled.View`
margin-left: 10px;
`

const SwitchElementComMargem = styled(SwitchElement)`
margin-bottom: 30px;
`;

const SubmmitButton = styled(ButtonElement)`
margin: 20px 0;
`


const ButtonContainer = styled.View`
margin-bottom: 20px;
`
