import React, {useEffect} from 'react';
import {View} from "react-native";
import { Button } from 'react-native-elements';
import styled from "styled-components/native";
import { AntDesign } from '@expo/vector-icons';
import {secundaryColor} from "../../styles/variables";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {produtoCreators} from "../../store/reducers/produto_reducer";
import InputElement from "../../components/InputElement";

const ProdutoCadastroStep1 = ({produto, pesquisarCodigo, primaryColor}) => {

    const {control, reset, handleSubmit}: any = useForm()

    useEffect(() => {
        reset({
            codigo: produto?.codigo,
        })
    }, [produto])


    const onSubmit = data => {
        pesquisarCodigo(data.codigo)
    }

    return (
        <Page>
            <View />
            <CenterContent>
            <LabelText>Digite o código do produto</LabelText>
            <Controller control={control} as={InputElement} placeholder={'INSIRA O CODIGO...'} name={'codigo'}  defaultvalue={''} />
            <DescriptionText>Caso este produto ja exista em nossa base, vamos preencher ele para voce</DescriptionText>
            </CenterContent>

            <Button
                icon={
                    <AntDesign name="checkcircle" size={18} color="white" style={{marginRight: 10}}/>
                }
                buttonStyle={{backgroundColor: primaryColor}}
                title="Avançar"
                onPress={handleSubmit(onSubmit)}
            />
        </Page>
    );
};

ProdutoCadastroStep1.routeName = 'produto-cadastro/step-1'

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor,
        produto: state?.produto?.produto,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    pesquisarCodigo: (codigo: string) => dispatch(produtoCreators.procurarCodigo(codigo)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoCadastroStep1);

const Page = styled.View`
flex: 1;
justify-content: space-between;
`

const CenterContent = styled.View`
padding: 30px;
`

const LabelText = styled.Text`
margin-left: 10px;
margin-bottom: 20px;
color: ${secundaryColor};
font-size: 26px;
`

const DescriptionText = styled.Text`
margin-left: 10px;
color: ${secundaryColor};
`
