import Action from "../../models/action.model";

export const ERROR_TYPES = {
    PUT: 'error/PUT',
    CLEAR: 'error/CLEAR',
}

export interface ErrorType {
    erro: string
}

export const INITIAL_STATE: ErrorType = {
    erro: null
}

export const errorCreators = {
    putError: (erro: string) => ({type: ERROR_TYPES.PUT, payload: erro}),
    clearError: () => ({type: ERROR_TYPES.CLEAR}),
}


export const error = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ERROR_TYPES.PUT:
            return {...state, erro: action.payload}
        case ERROR_TYPES.CLEAR:
            return {...state, erro: null}
        default:
            return state
    }
}