import {all, call, put, takeEvery, select} from "redux-saga/effects";
import api from "../../config/Network";
import Action from "../../models/action.model";
import {CONFIGURACAO_TYPES, configuracaoCreators} from "../reducers/configuracao_reducer";
import {lowerFirstLetter, upperFirstLetter} from "../../util/transform_case";
import {USUARIO_TYPES} from "../reducers/usuario_reducer";
import Configuracao from "../../models/configuracao.model";
import {primaryColorDefault} from "../../styles/variables";
import * as _ from 'lodash'
import {State} from "../reducers";
import {navigationRef} from "../../config/RoutesConfig";
import DetalhesScreen from "../../screens/loja/DetalhesScreen";
import {AnyAction} from "redux";

function* salvarConfiguracao({payload}: Action) {
    try {
        const nomeBanco =  yield select((state: State) => state.usuario.empresaSelecionada.NOME_BANCO)
        const prevConfig = yield select((state: State) => state.configuracao.configuracao)
        const configCompleta = _.merge(prevConfig, payload)

        const reqBody = upperFirstLetter(configCompleta)

        yield call(api.post, `/superzapp/${nomeBanco}/app/configurar`, reqBody)

        yield put(configuracaoCreators.salvarConfiguracaoSuccess())
    } catch (e) {
        console.log(e)
    }
}

function* configuracaoSalva() {
    yield put(configuracaoCreators.carregarConfiguracao())
    navigationRef.current?.navigate(DetalhesScreen.routeName)
}

function* carregarConfiguracao() {
    const nomeBanco =  yield select((state: State) => state.usuario.empresaSelecionada.NOME_BANCO)

    let {data} = yield call(api.get, `/superzapp/${nomeBanco}/parametrossuperzapp?$expand=IdWebTemplate`)
    data = data.value[0]
    const configuracao: Configuracao = lowerFirstLetter(data)

    if (!configuracao.idWebTemplate) {
        configuracao.idWebTemplate = {cor: primaryColorDefault}
    } else if (!configuracao.idWebTemplate.cor) {
        configuracao.idWebTemplate.cor = primaryColorDefault
    }
    yield put(configuracaoCreators.carregarConfiguracaoSuccess(configuracao))
}

function* uploadLogo({payload}: AnyAction) {
    try {
        const request = (body: any) => api.post(`/upload-imagem/logo`, body, {baseURL: 'https://iedx4igb3d.execute-api.us-east-1.amazonaws.com/prod'})

        const {data} = yield call(request, {imagem: payload})
        yield put(configuracaoCreators.salvarConfiguracao({idWebTemplate: {logo: data.url}}))
    } catch (e) {

    }
}

export function* configuracaoSaga(): any {
    yield all([
        takeEvery(CONFIGURACAO_TYPES.SALVAR, salvarConfiguracao),
        takeEvery(CONFIGURACAO_TYPES.SALVAR_SUCCESS, configuracaoSalva),
        takeEvery(CONFIGURACAO_TYPES.CARREGAR, carregarConfiguracao),
        takeEvery(USUARIO_TYPES.LOGIN_COMPLETED, carregarConfiguracao),
        takeEvery(CONFIGURACAO_TYPES.UPLOAD_LOGO, uploadLogo),
    ])
}
