import React, {useState} from 'react';
import {Image, StyleSheet, TouchableOpacity, View} from "react-native";
import * as ImagePicker from "expo-image-picker";
import {currentPlatform} from "../util/platform";
import {ModalMobileUploadImagem, ModalUploadImagem} from "../screens/loja/ModalUploadImagem";
import CameraScreen from "../screens/CameraScreen";
import { useNavigation } from '@react-navigation/native';

const defaultLogo = require('../../assets/cam.png')

const ImageUpload = ({setImage, image}) => {

    const handleSelectedImage = (base64Image) => {

        setImage(base64Image)
    }

    const navigation = useNavigation()

    let openImagePickerAsync = async () => {
        let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();

        if (permissionResult.granted === false) {
            alert("Permission to access camera roll is required!");
            return;
        }

        let pickerResult: any = await ImagePicker.launchImageLibraryAsync({
            base64: true,
        });
        if (pickerResult && (pickerResult.base64 || pickerResult.uri)) {
            handleSelectedImage(pickerResult.base64 ? pickerResult.base64 : pickerResult.uri)
        }
    }

    const [showImageDialog, setShowImageDialog] = useState<boolean>(false)

    const navigateToCam = () => {
        //TODO navegagar pela store para nao gerar dependencia ciclica
        navigation.navigate(CameraScreen.routeName)
    }

    return (
        <>
            <View style={styles.avatarContainer}>
                <TouchableOpacity onPress={() => setShowImageDialog(true)}>
                    <Image source={image ? {uri: image} : defaultLogo} style={ image ? {width: 250, height: 250} : {width: 120, height: 120}}/>
                </TouchableOpacity>
            </View>


            {
                currentPlatform.isWeb() ?
                    <ModalUploadImagem visible={showImageDialog} closeModal={() => setShowImageDialog(false)}
                                       openGalery={openImagePickerAsync}
                                       openCamera={navigateToCam}/>
                    : <ModalMobileUploadImagem visible={showImageDialog} closeModal={() => setShowImageDialog(false)}
                                               openGalery={openImagePickerAsync}
                                               openCamera={navigateToCam}/>
            }

        </>
    );
};

export default ImageUpload;

const styles = StyleSheet.create({
    avatarContainer: {
        width: 220,
        height: 220,
        borderRadius: 110,
        alignSelf: "flex-start",
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
        overflow: 'hidden',
    },
})
