import Categoria from "../models/categoria.model";
import Produto from "../models/produto.model";

export const categoriaToCamel = (v: any) => {
    let isObject = false
    if(!Array.isArray(v)) {
        isObject = true
        v = [v]
    }

    const categorias: Categoria[] = v.map((x: any) => ({
        id: x.Id,
        nome: x.Nome
    }))

    if(isObject) {
        return categorias[0];
    }

    return categorias
}


export const produtoToCamel = (v: any) => {
    let isObject = false
    if(!Array.isArray(v)) {
        isObject = true
        v = [v]
    }

    const produtos: Produto[] = v.map((x: any) => ({
        id: x.Id,
        nome: x.Nome,
        valorVenda: x.ValorVenda,
        estDisponivel: x.EstDisponivel,
        idEmpresa: x.IdEmpresa,
        foto: x.Foto,
        idCategoria: x.IdCategoria,
        idProdutoEmpresa: x.IdProdutoEmpresa,
    }))

    if(isObject) {
        return produtos[0];
    }

    return produtos
}



export const upperFirstLetter = origObj => {
    if(!origObj) return origObj

    return Object.keys(origObj).reduce((newObj, key) => {
        const val = origObj[key];
        newObj[key.charAt(0).toUpperCase() + key.slice(1)] = (typeof val === 'object') ? upperFirstLetter(val) : val;
        return newObj;
    }, {});
}


export const lowerFirstLetter = origObj => {
    if(!origObj) return origObj

    return Object.keys(origObj).reduce((newObj, key) => {
        const val = origObj[key];
        newObj[key.charAt(0).toLowerCase() + key.slice(1)] = (typeof val === 'object') ? lowerFirstLetter(val) : val;
        return newObj;
    }, {});
}

export const lowerAll = value => {
    if(!value) return value

    if(Array.isArray(value)) {
        return value.map(v => convertAllToLower(v))
    }

    return convertAllToLower(value)
}

const convertAllToLower = value => {
    return Object.keys(value).reduce((newObj, key) => {
        const val = value[key];
        const newKey = key.toLowerCase()
        newObj[snakeToCamel(newKey)] = (typeof val === 'object') ? convertAllToLower(val) : val;
        return newObj;
    }, {});
}

export const snakeToCamel = str => str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
