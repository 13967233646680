import React from 'react';
import {Input} from "react-native-elements";
import {outline} from "../util/style_suppport";

const InputElement = (props) => {

    let inputStyles: any = {
        inputStyle: {
            ...props.style,
            ...outline(),
            paddingLeft: 5,
        },
    }

    if (props.whiteColor) {
        inputStyles = {
            inputStyle: {
                ...inputStyles.inputStyle,
                color: '#fff',
            },
            labelStyle: {
                color: '#fff',
            },
            inputContainerStyle: {
                borderBottomColor: '#fff',
            },
        }
    }

    const {onChange, ...p} = props

    return (
        // @ts-ignore
        <Input {...p} {...inputStyles} onChangeText={(e: any) => {
            onChange(e)
        }} />
    )

};

export default InputElement;
