import React, {useEffect} from 'react';
import {View} from "react-native";
import {useDispatch} from "react-redux";

const Logout = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: 'LOGOUT'})
    }, [])

    return (
        <View>

        </View>
    );
};

Logout.routeName = 'logout'

export default Logout;
