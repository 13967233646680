import Action from "../../models/action.model";
import Configuracao from "../../models/configuracao.model";

export const CONFIGURACAO_TYPES = {
    SALVAR: 'configuracao/SALVAR',
    SALVAR_SUCCESS: 'configuracao/SALVAR_SUCCESS',
    CHANGE_COLOR: 'configuracao/CHANGE_COLOR',
    CARREGAR: 'configuracao/CARREGAR',
    CARREGAR_SUCCESS: 'configuracao/CARREGAR_SUCCESS',
    UPLOAD_LOGO: 'configuracao/UPLOAD_LOGO',
    UPLOAD_LOGO_SUCCESS: 'configuracao/UPLOAD_LOGO_SUCCESS',
}

export interface ConfiguracaoType {
    configuracao: Configuracao
}

export const INITIAL_STATE: ConfiguracaoType = {
    configuracao: {
        idWebTemplate: {
            cor: 'rgb(84, 156, 206)',
        }
    },
}

export const configuracaoCreators = {
    salvarConfiguracao: (configuracao: Configuracao) => ({type: CONFIGURACAO_TYPES.SALVAR, payload: configuracao}),
    salvarConfiguracaoSuccess: () => ({type: CONFIGURACAO_TYPES.SALVAR_SUCCESS, payload: configuracao}),
    changePrimaryColor: (primaryColor: String) => ({type: CONFIGURACAO_TYPES.CHANGE_COLOR, payload: primaryColor}),
    carregarConfiguracao: () => ({type: CONFIGURACAO_TYPES.CARREGAR}),
    carregarConfiguracaoSuccess: (configuracao: Configuracao) => ({type: CONFIGURACAO_TYPES.CARREGAR_SUCCESS, payload: configuracao}),
    uploadLogo: (base64: string) => ({type: CONFIGURACAO_TYPES.UPLOAD_LOGO, payload: base64}),
    uploadLogoSuccess: (url: string) => ({type: CONFIGURACAO_TYPES.UPLOAD_LOGO_SUCCESS, payload: url}),
}


export const configuracao = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case CONFIGURACAO_TYPES.CHANGE_COLOR:
            const config = {...state.configuracao}
            config.idWebTemplate.cor = action.payload
            return {...state, configuracao: config}
        case CONFIGURACAO_TYPES.CARREGAR_SUCCESS:
            return {...state, configuracao: action.payload}
        case CONFIGURACAO_TYPES.UPLOAD_LOGO_SUCCESS:
            return {...state, configuracao: {...state.configuracao, idWebTemplate: {...state.configuracao.idWebTemplate, logo: action.payload}}}
        default:
            return state
    }
}
