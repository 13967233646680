import {Modal, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {EvilIcons} from "@expo/vector-icons";
import styled from "styled-components/native";
import React from "react";
import ModalWeb from 'modal-enhanced-react-native-web';

const CommomContent = ({openCamera, openGalery, closeModal}) => {
    return (
        <DialogBody>
            <TouchableOpacity onPress={() => {
                openCamera()
                closeModal()
            }}>
                <DialogItem>
                    <EvilIcons name="camera" size={24} color="black" style={{marginRight: 20}}/>
                    <Text>Camera</Text>
                </DialogItem>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
                openGalery()
                closeModal()
            }}>
                <DialogItem>
                    <EvilIcons name="image" size={24} color="black" style={{marginRight: 20}}/>
                    <Text>Galeria</Text>
                </DialogItem>
            </TouchableOpacity>
        </DialogBody>
    )
}

export const ModalUploadImagem = (props) => {
    const {visible} = props
    return (
        <View>
            <ModalWeb isVisible={visible}
                // onBackdropPress={closeModal}
            >
                <CommomContent {...props} />
            </ModalWeb>
        </View>
    );
}

export const ModalMobileUploadImagem = (props) => {
    const {visible} = props
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={() => {
            }}
        >
            <View style={styles.background}>
                <TouchableOpacity onPress={() => console.log('teste')}>
                        <CommomContent {...props} />
                </TouchableOpacity>
            </View>
        </Modal>
    );
};

const DialogBody = styled.View`
background-color: #fff;
width: 80%;
margin-left: 10%;
`

const DialogItem = styled.View`
flex: 1;
height: 80px;
padding: 30px 40px;
flex-direction: row;
align-items: center;
justify-content: flex-start;
`

const styles = StyleSheet.create({

    background: {
        justifyContent: "center",

        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
    },
})
