import Produto from "../../models/produto.model";
import Action from "../../models/action.model";

export const PRODUTO_TYPES = {
    CATEGORIA_DEFINIR: 'categoria/DEFINIR',

    CODIGO_SEARCH_BEGIN: 'codigo/SEARCH_BEGIN',
    CODIGO_SEARCH_SUCCESS: 'codigo/SEARCH_SUCCESS',
    CODIGO_SEARCH_ERROR: 'codigo/SEARCH_ERROR',

    PRODUTO_BUSCAR_BEGIN: 'produto/BUSCAR_BEGIN',
    PRODUTO_BUSCAR_SUCCESS: 'produto/BUSCAR_SUCCESS',
    PRODUTO_BUSCAR_ERROR: 'produto/BUSCAR_ERROR',

    PRODUTO_CONCLUIR_STEP_1: 'produto/CONCLUIR_STEP_1',
    PRODUTO_CONCLUIR_STEP_2: 'produto/CONCLUIR_STEP_2',

    PRODUTO_SALVAR_BEGIN: 'produto/SALVAR_BEGIN',
    PRODUTO_SALVAR_SUCCESS: 'produto/SALVAR_SUCCESS',
    PRODUTO_SALVAR_ERROR: 'produto/SALVAR_ERROR',

    NOVO_PRODUTO: 'produto/NOVO_PRODUTO',
    EDITAR_PRODUTO: 'produto/EDITAR_PRODUTO',

    UPLOAD_FOTO: 'configuracao/UPLOAD_FOTO',
    UPLOAD_FOTO_SUCCESS: 'configuracao/UPLOAD_FOTO_SUCCESS',
}

export interface ProdutoType {
    produto: Produto
}

export const INITIAL_STATE: ProdutoType = {
    produto: null,
}

export const produtoCreators = {
    defineCategoria: (categoriaId: number) => ({type: PRODUTO_TYPES.CATEGORIA_DEFINIR, payload: categoriaId}),
    procurarCodigo: (value: string) => ({type: PRODUTO_TYPES.CODIGO_SEARCH_BEGIN, payload: value}),
    procurarCodigoSuccess: (produto: Produto) => ({type: PRODUTO_TYPES.CODIGO_SEARCH_SUCCESS, payload: produto}),
    procurarCodigoError: (value: string) => ({type: PRODUTO_TYPES.CODIGO_SEARCH_ERROR, payload: value}),

    buscarProduto: (id: number) => ({type: PRODUTO_TYPES.PRODUTO_BUSCAR_BEGIN, payload: id}),
    buscarProdutoSucess: (value: any) => ({type: PRODUTO_TYPES.PRODUTO_BUSCAR_SUCCESS, payload: value}),

    concluirStep1: (codigo: string) => ({type: PRODUTO_TYPES.PRODUTO_CONCLUIR_STEP_1, payload: codigo}),
    concluirStep2: (produto: Produto) => ({type: PRODUTO_TYPES.PRODUTO_CONCLUIR_STEP_2, payload: produto}),

    produtoSalvar: (produto: Produto) => ({type: PRODUTO_TYPES.PRODUTO_SALVAR_BEGIN, payload: produto}),
    produtoSalvarSuccess: () => ({type: PRODUTO_TYPES.PRODUTO_SALVAR_SUCCESS}),
    produtoSalvarError: (value: string) => ({type: PRODUTO_TYPES.PRODUTO_SALVAR_ERROR, payload: value}),

    novoProduto: () => ({type: PRODUTO_TYPES.NOVO_PRODUTO}),
    editarProduto: (produto: Produto) => ({type: PRODUTO_TYPES.EDITAR_PRODUTO, payload: produto}),

    uploadFoto: (value: any) => ({type: PRODUTO_TYPES.UPLOAD_FOTO, payload: value}),
    uploadFotoSuccess: (url: string) => ({type: PRODUTO_TYPES.UPLOAD_FOTO_SUCCESS, payload: url}),
}

export const produto = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case PRODUTO_TYPES.CATEGORIA_DEFINIR:
            return {...state, produto: {...state.produto, idCategoria: action.payload}}
        case PRODUTO_TYPES.PRODUTO_CONCLUIR_STEP_1:
            return {...state, produto: {...state.produto, codigo: action.payload}}
        case PRODUTO_TYPES.PRODUTO_CONCLUIR_STEP_2:
            return {...state, produto: {...state.produto, ...action.payload}}
        case PRODUTO_TYPES.CODIGO_SEARCH_SUCCESS:
            return {...state, produto: {...state.produto, ...action.payload}}
        case PRODUTO_TYPES.NOVO_PRODUTO:
            return {...state, produto: {idCategoria: state?.produto?.idCategoria}}
        case PRODUTO_TYPES.EDITAR_PRODUTO:
            return {...state, produto: action.payload}
        case PRODUTO_TYPES.UPLOAD_FOTO_SUCCESS:
            return {...state, produto: {...state.produto, foto: action.payload}}
        default:
            return state
    }
}