import React, {useEffect} from 'react';
import Form from "../../layout/Form";
import styled from "styled-components/native";
import {Controller, useForm} from "react-hook-form";
import InputElement from "../../components/InputElement";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import Produto from "../../models/produto.model";
import {connect} from "react-redux";
import {AntDesign} from "@expo/vector-icons";
import {secundaryColor} from "../../styles/variables";
import {Button} from "react-native-elements";
import {produtoCreators} from "../../store/reducers/produto_reducer";
import { toNumber } from '../../util/math_util';
import InputMonetario from "../../components/InputMonetario";


const ProdutoCadastroStep2 = ({produto, avancar, primaryColor, ...props}) => {

    const {control, handleSubmit, reset}: any = useForm()

    useEffect(() => {
        reset({
            nome: produto.nome,
            valorVenda: produto.valorVenda?.toString(),
            codigo: produto.codigo,
        })
    }, [produto])

    const onSubmit = data => {
        avancar({...produto, nome: data.nome, valorVenda: toNumber(data.valorVenda), codigo: data.codigo})
    }

    return (
        <Page>
            <LabelText>Digite os dados do produto que deseja adicionar</LabelText>
            <FormAndButton>
                <Form style={{alignSelf: 'center'}}>
                    <Controller control={control} label={'Nome'} as={InputElement} name="nome" defaultValue={''}/>
                    <Controller control={control} label={'Preco'} as={InputMonetario} name="valorVenda"  defaultValue={''}/>
                    <Controller control={control} label={'Codigo'} as={InputElement} name="codigo"  defaultValue={''}/>
                </Form>
                <Button icon={<AntDesign name="checkcircle" size={18} color="white" style={{marginRight: 10}}/>}
                        buttonStyle={{backgroundColor: primaryColor}} title="Avançar" onPress={handleSubmit(onSubmit)}/>
            </FormAndButton>
        </Page>
    );
};

ProdutoCadastroStep2.routeName = 'produto-cadastro/step-2'

const mapStateToProps = (state: State) => {
    return {
        produto: state.produto.produto,
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    avancar: (produto: Produto) => dispatch(produtoCreators.concluirStep2(produto)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoCadastroStep2);

const Page = styled.View`
flex: 1;
`

const FormAndButton = styled.View`
flex: 1;
justify-content: space-between;
`

const LabelText = styled.Text`
margin-left: 10px;
margin-bottom: 20px;
margin-top: 100px;
color: ${secundaryColor};
font-size: 26px;
text-align: center;
`
