import React from 'react';
import {State} from "../store/reducers";
import {connect} from "react-redux";
import {Button} from "react-native-elements";

const ButtonElement = ({primaryColor, buttonStyle = {}, ...props}) => {
    buttonStyle = {...buttonStyle, backgroundColor: primaryColor}
    return (
       <Button buttonStyle={buttonStyle} {...props}/>
    );
};

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate.cor,
    }
}

export default connect(mapStateToProps, null)(ButtonElement);
