import {all, call, put, takeEvery, select} from "redux-saga/effects";
import api from "../../config/Network";
import Action from "../../models/action.model";
import Produto from "../../models/produto.model";
import {navigationRef} from "../../config/RoutesConfig";
import ProdutoCadastroStep2 from "../../screens/catalogo/ProdutoCadastroStep2";
import CatalogoScreen from "../../screens/catalogo/CatalogoScreen";
import ProdutoCadastroStep3 from "../../screens/catalogo/ProdutoCadastroStep3";
import ProdutosScreen from "../../screens/catalogo/ProdutosScreen";
import {PRODUTO_TYPES, produtoCreators} from "../reducers/produto_reducer";
import ProdutoCadastroScreen from "../../screens/catalogo/ProdutoCadastroScreen";
import {AnyAction} from "redux";
import {errorCreators} from "../reducers/error_reducer";
import {State} from "../reducers";




function* definirCategoria({payload}: Action) {
    navigationRef.current?.navigate(ProdutosScreen.routeName, {categoriaId: payload})
}

function* buscarProduto({payload}: Action) {
    try {
        const nomeBanco =  yield select((state: State) => state.usuario.empresaSelecionada.NOME_BANCO)

        let {data} = yield call(api.get, `/cad/${nomeBanco}/produto(${payload})`)

        let produto: Produto = {
            nome: data.Nome,
            valorVenda: data.ValorVenda,
            foto: data.Foto,
        }
        yield put(produtoCreators.concluirStep1(payload))
        yield put(produtoCreators.procurarCodigoSuccess(produto))
        // navigationRef.current?.navigate(ProdutoCadastroStep2.routeName)

    } catch (e) {
        console.log(e)
    }
}

function* procurarCodigo({payload}: Action) {
    if(!payload) {
        navigationRef.current?.navigate(ProdutoCadastroStep2.routeName)
        return
    }

    try {
        const nomeBanco =  yield select((state: State) => state.usuario.empresaSelecionada.NOME_BANCO)
        let {data} = yield call(api.get, `/cad/${nomeBanco}/produtos/produtos?Texto='${payload}'&CampoOrdem=Nome&Pagina=0&Limite=1000`)
        data = data.value

        let produto: Produto = {
            nome: null,
            valorVenda: null,
            foto: null,
        }

        if (data.length === 1) {
            data = data[0]
            produto = {
                nome: data.Nome,
                valorVenda: data.ValorVenda,
                foto: data.Foto,
            }
        }

        yield put(produtoCreators.procurarCodigoSuccess({...produto, codigo: payload}))
        navigationRef.current?.navigate(ProdutoCadastroStep2.routeName)

    } catch (e) {
        console.log(e)
    }
}

function* salvarProduto({payload}: Action) {
    try {
        const nomeBanco =  yield select((state: State) => state.usuario.empresaSelecionada.NOME_BANCO)

        //A propriedade abaixo se chama oto mesmo e nao Foto
        const produtoSalvar = {
            Id: payload.id,
            Nome: payload.nome,
            Codigo: payload.codigo,
            Photo: payload.foto,
            IdCategoria: payload.idCategoria,
            IdProdutoEmpresa: payload.idProdutoEmpresa,
            Preco: payload.valorVenda,
        }

        const {status, data} = yield call(api.post, `/superzapp/${nomeBanco}/app/produto`, produtoSalvar)
        if(status > 299) {
            yield put(errorCreators.putError(data?.error?.message))
            throw new Error(data?.error?.message)
        }
        yield put(produtoCreators.produtoSalvarSuccess())

    } catch (e) {
        console.log(e)
    }
}

function* produtoSalvo() {
    navigationRef.current?.navigate(CatalogoScreen.routeName)
}

function* concluirStep2() {
    navigationRef.current?.navigate(ProdutoCadastroStep3.routeName)
}

function* uploadImage({payload}: AnyAction) {
    try {
        const request = (body: any) => api.post(`/upload-imagem/produto`, body, {baseURL: 'https://iedx4igb3d.execute-api.us-east-1.amazonaws.com/prod'})

        const {data} = yield call(request, payload)
        yield put(produtoCreators.uploadFotoSuccess(data.url))
    } catch (e) {

    }
}


function* editarProduto() {
    navigationRef.current?.navigate(ProdutoCadastroScreen.routeName)
}

function* novoProduto() {
    navigationRef.current?.navigate(ProdutoCadastroScreen.routeName)
}

export function* cadastroProdutoSaga(): any {
    yield all([
        takeEvery(PRODUTO_TYPES.CATEGORIA_DEFINIR, definirCategoria),
        takeEvery(PRODUTO_TYPES.CODIGO_SEARCH_BEGIN, procurarCodigo),
        takeEvery(PRODUTO_TYPES.PRODUTO_SALVAR_BEGIN, salvarProduto),
        takeEvery(PRODUTO_TYPES.PRODUTO_SALVAR_SUCCESS, produtoSalvo),
        takeEvery(PRODUTO_TYPES.PRODUTO_CONCLUIR_STEP_2, concluirStep2),
        takeEvery(PRODUTO_TYPES.PRODUTO_BUSCAR_BEGIN, buscarProduto),
        takeEvery(PRODUTO_TYPES.UPLOAD_FOTO, uploadImage),
        takeEvery(PRODUTO_TYPES.EDITAR_PRODUTO, editarProduto),
        takeEvery(PRODUTO_TYPES.NOVO_PRODUTO, novoProduto),
    ])
}
