import React, {useEffect} from 'react';
import {createDrawerNavigator} from "@react-navigation/drawer";
import LojaScreen from "./loja/LojaScreen";
import CatalogoScreen from "./catalogo/CatalogoScreen";
import DesignScreen from "./design/DesignScreen";
import Logout from "./Logout";
import {useDispatch} from "react-redux";
import {configuracaoCreators} from "../store/reducers/configuracao_reducer";

const HomeScreen = () => {

    const Drawer = createDrawerNavigator()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(configuracaoCreators.carregarConfiguracao())
    }, [])

    return (
        <Drawer.Navigator>
            <Drawer.Screen name={LojaScreen.routeName} component={LojaScreen} />
            <Drawer.Screen name={CatalogoScreen.routeName} component={CatalogoScreen} options={{drawerLabel: 'Catalogo de Produtos'}} />
            <Drawer.Screen name={DesignScreen.routeName} component={DesignScreen} />
            <Drawer.Screen name={Logout.routeName} component={Logout} options={{drawerLabel: 'Sair'}}   />
        </Drawer.Navigator>
    );
};

HomeScreen.routeName = 'home'

export default HomeScreen;

