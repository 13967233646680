import React, {useEffect} from 'react';
import {CardStyleInterpolators, createStackNavigator} from "@react-navigation/stack";
import RegisterStep1Screen from "./RegisterStep1Screen";
import RegisterStep2Screen from "./RegisterStep2Screen";
import {connect} from "react-redux";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {cadastroCreators} from "../../store/reducers/cadastro_reducer";
import RegisterStep3Screen from "./RegisterStep3Screen";



const RegisterScreen = ({primaryColor, listarRevendas}) => {
    useEffect(() => {
        listarRevendas()
    }, [])

    const Stack = createStackNavigator()

    const headerStyle = {
        headerStyle: {
            backgroundColor: primaryColor,
        },
        headerTintColor: '#fff',
    }

    return (
            <Stack.Navigator screenOptions={{
                animationEnabled: true,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            }}>
                <Stack.Screen name={RegisterStep1Screen.routeName} component={RegisterStep1Screen} options={{
                    ...headerStyle
                }} />
                <Stack.Screen name={RegisterStep2Screen.routeName} component={RegisterStep2Screen} options={{
                    ...headerStyle
                }} />
                <Stack.Screen name={RegisterStep3Screen.routeName} component={RegisterStep3Screen} options={{
                    ...headerStyle
                }} />
            </Stack.Navigator>
    );
};

RegisterScreen.routeName = 'register'

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    listarRevendas: () => dispatch(cadastroCreators.listarRevendas()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);