import Action from "../../models/action.model";
import {Cadastro} from "../../models/cadastro.model";
import {Revenda} from "../../models/revenda.model";

export const CADASTRO_TYPES = {
    CONCLUIR_PRIMEIRA_ETAPA: 'cadastro/CONCLUIR_PRIMEIRA_ETAPA',
    CONCLUIR_SEGUNDA_ETAPA: 'cadastro/CONCLUIR_SEGUNDA_ETAPA',
    CONCLUIR_TERCEIRA_ETAPA: 'cadastro/CONCLUIR_TERCEIRA_ETAPA',
    CONCLUIDO: 'cadastro/CONCLUIDO',
    LISTAR_REVENDAS: 'cadastro/LISTAR_REVENDAS',
    LISTAR_REVENDAS_SUCCESS: 'cadastro/LISTAR_REVENDAS_SUCCESS',
    BUSCAR_INFORMACAO_EMPRESA: 'cadastro/BUSCAR_INFORMACAO_EMPRESA',
    BUSCAR_INFORMACAO_EMPRESA_SUCCESS: 'cadastro/BUSCAR_INFORMACAO_EMPRESA_SUCCESS',
    CADASTRAR: 'cadastro/CADASTRAR',
    CADASTRAR_SUCCCESS: 'cadastro/CADASTRAR_SUCCCESS',
    PESQUISA_CEP: 'cadastro/PESQUISA_CEP',
    PESQUISA_CEP_SUCCCESS: 'cadastro/PESQUISA_CEP_SUCCCESS',
}

export interface CadastroType {
    cadastroForm: Cadastro
    revendas: Revenda[]
}

export const INITIAL_STATE: CadastroType = {
    cadastroForm: null,
    revendas: []
}

export const cadastroCreators = {
    concluirPrimeiraEtapa: (value: Cadastro) => ({type: CADASTRO_TYPES.CONCLUIR_PRIMEIRA_ETAPA, payload: value}),
    concluirSegundaEtapa: (value: Cadastro) => ({type: CADASTRO_TYPES.CONCLUIR_SEGUNDA_ETAPA, payload: value}),
    concluirTerceiraEtapa: (value: Cadastro) => ({type: CADASTRO_TYPES.CONCLUIR_TERCEIRA_ETAPA, payload: value}),
    cadastroConcluido: () => ({type: CADASTRO_TYPES.CONCLUIDO}),
    buscaInformacoesEmpresaSuccess: (value: Cadastro) => ({type: CADASTRO_TYPES.BUSCAR_INFORMACAO_EMPRESA_SUCCESS, payload: value}),
    pesquisaCep: (cep: string) => ({type: CADASTRO_TYPES.PESQUISA_CEP, payload: cep}),
    pesquisaCepSuccess: (value: Cadastro) => ({type: CADASTRO_TYPES.PESQUISA_CEP_SUCCCESS, payload: value}),
    listarRevendas: () => ({type: CADASTRO_TYPES.LISTAR_REVENDAS}),
    listarRevendasSuccess: (revendas: Revenda[]) => ({type: CADASTRO_TYPES.LISTAR_REVENDAS_SUCCESS, payload: revendas}),
}

export const cadastro = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case CADASTRO_TYPES.CONCLUIR_PRIMEIRA_ETAPA:
            return {...state, cadastroForm: action.payload}
        case CADASTRO_TYPES.CONCLUIR_SEGUNDA_ETAPA:
            return {...state, cadastroForm: {...state.cadastroForm, ...action.payload}}
        case CADASTRO_TYPES.BUSCAR_INFORMACAO_EMPRESA_SUCCESS:
            return {...state, cadastroForm: {...state.cadastroForm, ...action.payload}}
        case CADASTRO_TYPES.PESQUISA_CEP_SUCCCESS:
            return {...state, cadastroForm: {...state.cadastroForm, ...action.payload}}
        case CADASTRO_TYPES.CONCLUIR_TERCEIRA_ETAPA:
            return {...state, cadastroForm: {...state.cadastroForm, ...action.payload}}
        case CADASTRO_TYPES.CONCLUIDO:
            return {...state, cadastroForm: null}
        case CADASTRO_TYPES.LISTAR_REVENDAS_SUCCESS:
            return {...state, revendas: action.payload}
        default:
            return state
    }
}

