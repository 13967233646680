import React, {useEffect, useRef, useState} from 'react';
import {TouchableOpacity, View, Text} from "react-native";
import {Camera} from "expo-camera";
import {currentPlatform} from "../util/platform";
import styled from "styled-components/native";

const CameraScreen = ({navigation}) => {

    const [hasPermission, setHasPermission]: any = useState(null);
    const [type, setType] = useState(Camera.Constants.Type.back);

    const camera: any = useRef()

    useEffect(() => {
        (async () => {
            if (currentPlatform.isWeb()) {
                if (await Camera.isAvailableAsync()) {
                    setType(await Camera.getAvailableCameraTypesAsync());
                }
            } else {
                const {status} = await Camera.requestPermissionsAsync();
                setHasPermission(status === 'granted');
            }
        })();

    }, []);

    if (!currentPlatform.isWeb() && (hasPermission === null || hasPermission === false)) {
        return <Text>No access to camera</Text>;
    }

    const handleTakePhoto = async () => {
        if (!camera) return
        const result = await camera.current.takePictureAsync({
            base64: true,
        })
        console.log(result)
        navigation.push('produto-cadastro/step-3', {base64Image: result.base64})
        if (navigation.canGoBack()) {
            navigation.goBack()
        }
    }

    return (
        <View style={{flex: 1}}>
            <Camera ref={camera} style={{flex: 1, justifyContent: 'flex-end'}}>
                <TouchableOpacity onPress={handleTakePhoto}>
                    <TakePictureButtonContainer>
                        <TakePictureButton/>
                    </TakePictureButtonContainer>
                </TouchableOpacity>
            </Camera>
        </View>
    );
}

CameraScreen.routeName = 'cam'

export default CameraScreen;

const TakePictureButtonContainer = styled.View`
width: 80px;
height: 80px;
border-radius: 40px;
background-color: transparent;
border: 1px solid #fff;
align-self: center;
margin-bottom: 40px;
`

const TakePictureButton = styled.View`
width: 90%;
height: 90%;
border-radius: 45px;
margin-top: 5%;
margin-left: 5%;
background-color: #fff;
`
