import {all, takeEvery, call, put, select} from "redux-saga/effects";
import api, {setToken} from "../../config/Network";
import Action from "../../models/action.model";
import {USUARIO_TYPES, usuarioCreators} from "../reducers/usuario_reducer";
import {State} from "../reducers";
import {navigationRef} from "../../config/RoutesConfig";
import IntermediariaScreen from "../../screens/auth/IntermediariaScreen";


function* requestPrimeiraEtapa({payload}: Action) {
    try {
        const reqBody = {
            User: payload.usuario,
            Password: payload.senha,
        }

        let {data} = yield call(api.post, `/auth/LoginService/Login`, reqBody)

        yield put(usuarioCreators.logarPrimeiraEtapaSucesso({...payload, tokenTemporario: data.value}))
    } catch (e) {
        console.log(e)
    }
}


function* requestSegundaEtapa({payload}: Action) {
    try {
        const reqBody = {
            User: payload.usuario,
            Password: payload.senha,
        }

        const apiCall = () => {
            return api.post(`/security/SecurityUserService/LoginUser`, reqBody, {headers: {Authorization: `Bearer ${payload.tokenTemporario}`}})
        }

        let {data} = yield call(apiCall)

        yield put(usuarioCreators.logarSegundaEtapaSucesso(data))
    } catch (e) {
        console.log(e)
    }
}


function* requestTerceiraEtapa({payload}: Action) {
    try {
        const tokenTemporario = yield select((state: State) => state.usuario.usuarioLoginProcess?.tokenTemporario)

        const request = (body, token) => api.post(`/security/SecurityUserService/UsuarioEmpresas`, body, {headers: {Authorization: `Bearer ${token}`}})

        let {data} = yield call(request, payload, tokenTemporario)
        yield put(usuarioCreators.logarTerceiraEtapaSucesso(data.value))
    } catch (e) {
        console.log(e)
    }
}


function* requestQuartaEtapa({payload}: Action) {
    try {
        const {tokenTemporario, usuario} = yield select((state: State) => {
            return {
                tokenTemporario: state.usuario.usuarioLoginProcess?.tokenTemporario,
                usuario: state.usuario.usuarioLogado,
            }
        })

        const request = (body: any, token) => api.post(`/security/SecurityUserService/LoginUsuarioEmpresa`, body, {headers: {Authorization: `Bearer ${token}`}})

        let {data} = yield call(request, {
            IdCli: Number(payload.ID),
            Usuario: usuario,
        }, tokenTemporario)

        setToken(`Bearer ${data.value}`)

        yield put(usuarioCreators.loginCompleted(data.value))
    } catch (e) {
        console.log(e)
    }
}

function* adicionaToken() {
    const token = yield select((state: State) => state.usuario.token)

    if(token) {
    setToken(`Bearer ${token}`)
    }
}

function* terceiraEtapaSuccess() {
    yield navigationRef.current?.navigate(IntermediariaScreen.routeName)
}

function* selecionouEmpresa({payload}: any) {
    yield put(usuarioCreators.logarQuartaEtapa(payload))
}

export function* usuarioSaga(): any {
    yield all([
        takeEvery(USUARIO_TYPES.LOGAR_PRIMEIRA_ETAPA, requestPrimeiraEtapa),
        takeEvery(USUARIO_TYPES.LOGAR_PRIMEIRA_ETAPA_SUCCESS, requestSegundaEtapa),
        takeEvery(USUARIO_TYPES.LOGAR_SEGUNDA_ETAPA_SUCCESS, requestTerceiraEtapa),
        takeEvery(USUARIO_TYPES.LOGAR_TERCEIRA_ETAPA_SUCCESS, terceiraEtapaSuccess),
        takeEvery(USUARIO_TYPES.SELECIONA_EMPRESA, selecionouEmpresa),
        takeEvery(USUARIO_TYPES.LOGAR_QUARTA_ETAPA, requestQuartaEtapa),
        takeEvery('persist/REHYDRATE', adicionaToken),
        // takeEvery('LOGOUT', ),
    ])
}
