import Categoria from "../../models/categoria.model";
import Produto from "../../models/produto.model";
import Action from "../../models/action.model";

export const API_DATA_TYPES = {
    CATEGORIA_LIST_BEGIN: 'categoria/LIST_BEGIN',
    CATEGORIA_LIST_SUCCESS: 'categoria/LIST_SUCCESS',
    CATEGORIA_LIST_ERROR: 'categoria/LIST_ERROR',

    PRODUTO_LIST_BEGIN: 'produto/LIST_BEGIN',
    PRODUTO_LIST_SUCCESS: 'produto/LIST_SUCCESS',
    PRODUTO_LIST_ERROR: 'produto/LIST_ERROR',
}

export interface ApiDataType {
    categorias: Categoria[]
    produtos: Produto[]
}

export const INITIAL_STATE: ApiDataType = {
    categorias: [],
    produtos: [],
}

export const apiDataCreators = {
    listarCategoria: () => ({type: API_DATA_TYPES.CATEGORIA_LIST_BEGIN}),
    listarCategoriaSuccess: (value: Categoria[]) => ({type: API_DATA_TYPES.CATEGORIA_LIST_SUCCESS, payload: value}),
    listarCategoriaError: (value: string) => ({type: API_DATA_TYPES.CATEGORIA_LIST_ERROR, payload: value}),

    listarProduto: (idCategoria: number) => ({type: API_DATA_TYPES.PRODUTO_LIST_BEGIN, payload: idCategoria}),
    listarProdutoSuccess: (value: Produto[]) => ({type: API_DATA_TYPES.PRODUTO_LIST_SUCCESS, payload: value}),
    listarProdutoError: (value: string) => ({type: API_DATA_TYPES.PRODUTO_LIST_ERROR, payload: value}),
}

export const apiData = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case API_DATA_TYPES.CATEGORIA_LIST_SUCCESS:
            return {...state, categorias: action.payload}
        case API_DATA_TYPES.PRODUTO_LIST_SUCCESS:
            return {...state, produtos: action.payload}
        default:
            return state
    }
}