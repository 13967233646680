import React, {useState} from 'react';
import styled from "styled-components/native";
import {primaryColorDefault} from "../../styles/variables";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {Cadastro} from "../../models/cadastro.model";
import {cadastroCreators} from "../../store/reducers/cadastro_reducer";
import {connect} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import InputElement from "../../components/InputElement";
import {Button} from "react-native-elements";
import {widthPercentageToDP as wp} from "react-native-responsive-screen";
import {errorCreators} from "../../store/reducers/error_reducer";
import {Picker} from "react-native";
import {Revenda} from "../../models/revenda.model";

const RegisterStep3Screen = ({concluir, putError, revendas}) => {

    const {control, handleSubmit, reset, getValues}: any = useForm()
    const [idRevenda, setIdRevenda] = useState(0)

    const onSubmit = data => {
        const {senhaMaster, repetirSenha} = data
        if(!senhaMaster.length) {
            putError('A senha nao pode ser vazia!')
            return
        }
        if(senhaMaster !== repetirSenha) {
            putError('As senhas nao sao iguais!')
            return
        }

        concluir({senhaMaster, idRevenda})
    }

    let pickerStyles: any = {
        height: 50,
        width: wp('90%'),
        backgroundColor: 'transparent',
        marginBottom: 20,
        border: 'none',
        color: '#fff',
    }

    return (
        <Page>
            <Controller control={control} label={'Senha'} as={InputElement} name="senhaMaster" defaultValue={''} whiteColor={true} secureTextEntry={true} />
            <Controller control={control} label={'Repita a senha'} as={InputElement} name="repetirSenha" defaultValue={''} whiteColor={true} secureTextEntry={true} />
            <PickerLabel>Revenda</PickerLabel>
            <Picker selectedValue={idRevenda} style={pickerStyles}
                    onValueChange={(value, index) => {
                        setIdRevenda(value)
                    }}>
                {
                    revendas?.map((revenda: Revenda) => <Picker.Item key={revenda.id} label={revenda.nome} value={revenda.id} />)
                }
            </Picker>

            <Button title={'CONCLUIR'} onPress={handleSubmit(onSubmit)} containerStyle={{width: wp('90%')}} />
        </Page>
    );
};

RegisterStep3Screen.routeName = 'register/step-3'

const mapStateToProps = (state: State) => {
    const revendas = [{id: 0, nome: '-', nomeBanco: ''}, ...state.cadastro.revendas]
    return {
        revendas,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    concluir: (cadastro: Cadastro) => dispatch(cadastroCreators.concluirTerceiraEtapa(cadastro)),
    putError: (erro: string) => dispatch(errorCreators.putError(erro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStep3Screen);

const Page = styled.View`
flex: 1;
justify-content: center;
align-items: center;
padding: 20px;
background-color: ${primaryColorDefault};
`

const PickerLabel = styled.Text`
align-self: flex-start;
color: #fff;
font-size: 16px;
font-weight: bold;
margin-left: 15px;
`