import {create} from 'apisauce'

const api = create({
    baseURL: 'https://api.toqweb.com.br:2004',
})

export const setToken = (token: string) => {
    api.setHeaders({
        Authorization: token,
    })
}

export default api
