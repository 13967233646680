import React from 'react';
import styled from "styled-components/native";
import { AntDesign } from '@expo/vector-icons';
import {State} from "../store/reducers";
import {connect} from "react-redux";

const FloatActionButton = ({icon = <AntDesign name="plus" size={28} color="white" />, onPress, primaryColor}) => {
    return (
        <FloatActionButtonContainer onPress={onPress}>
            <FloatActionButtonFilled fillColor={primaryColor}>
                {icon}
            </FloatActionButtonFilled>
        </FloatActionButtonContainer>
    );
};

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor,
    }
}

export default connect(mapStateToProps, null)(FloatActionButton);

const FloatActionButtonContainer = styled.TouchableOpacity`
width: 60px;
height: 60px;
position: absolute;
right: 30px;
bottom: 30px;
`

const FloatActionButtonFilled = styled.View`
background-color: ${({fillColor}) => fillColor};
flex: 1;
border-radius: 30px;
align-items: center;
justify-content: center;
`
