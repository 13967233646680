import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import InputElement from "../../components/InputElement";
import {Button} from "react-native-elements";
import {widthPercentageToDP as wp} from "react-native-responsive-screen";
import styled from "styled-components/native";
import {primaryColorDefault} from "../../styles/variables";
import {Dispatch} from "redux";
import {Cadastro} from "../../models/cadastro.model";
import {cadastroCreators} from "../../store/reducers/cadastro_reducer";
import {connect} from "react-redux";
import {State} from "../../store/reducers";
import { EvilIcons } from '@expo/vector-icons';
import {ScrollView, View} from "react-native";

interface ComponentProps {
    concluir: Function
    pesquisaCep: Function
    cadastro: Cadastro
}

const RegisterStep2Screen = ({concluir, cadastro, pesquisaCep}: ComponentProps) => {

    const {control, handleSubmit, reset, getValues}: any = useForm()

    useEffect(() => {
        reset({
            razaoSocial: cadastro?.razaoSocial,
            cep: cadastro?.cep,
            endereco: cadastro?.endereco,
            bairro: cadastro?.bairro,
            numero: cadastro?.numero,
            cidade: cadastro?.cidade,
            uf: cadastro?.uf,
        })
    }, [cadastro])

    const onSubmit = data => {
        concluir(data)
    }

    const handlePesquisaCep = () => {
        const cep = getValues('cep').replace(/[^\d]/g, '')
        console.log(cep)
        console.log(cep.length)
        if(cep.length === 8) {
            pesquisaCep(cep)
        }
    }

    return (
        <Page>
            <ScrollView>
                <Form>
                    <Controller control={control} label={'Razao Social'} as={InputElement} name="razaoSocial" defaultValue={''} whiteColor={true} />
                    <RowContainer>
                        <View style={{width: wp('85%')}}>
                            <Controller control={control} label={'Cep'} as={InputElement} name="cep" defaultValue={''} whiteColor={true} />
                        </View>
                        <Button icon={<EvilIcons name="search" size={24} color="white" />} onPress={handlePesquisaCep}/>
                    </RowContainer>
                    <Controller control={control} label={'Endereco'} as={InputElement} name="endereco" defaultValue={''} whiteColor={true} />
                    <RowContainer>
                        <View style={{width: wp('60%')}}>
                            <Controller control={control} label={'Bairro'} as={InputElement} name="bairro" defaultValue={''} whiteColor={true} />
                        </View>
                        <View style={{width: wp('35%')}}>
                            <Controller control={control} label={'Numero'} as={InputElement} name="numero" defaultValue={''} whiteColor={true} />
                        </View>
                    </RowContainer>
                    <RowContainer>
                        <View style={{width: wp('70%')}}>
                        <Controller control={control} label={'Cidade'} as={InputElement} name="cidade" defaultValue={''} whiteColor={true} />
                        </View>
                        <View style={{width: wp('25%')}}>
                        <Controller control={control} label={'Uf'} as={InputElement} name="uf" defaultValue={''} whiteColor={true} />
                        </View>
                    </RowContainer>
                    <Button title={'AVANCAR'} onPress={handleSubmit(onSubmit)} containerStyle={{width: wp('90%')}} />
                </Form>
            </ScrollView>
        </Page>
    );
};

RegisterStep2Screen.routeName = 'register/step-2'

const mapStateToProps = (state: State) => {
    return {
        cadastro: state?.cadastro?.cadastroForm,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    concluir: (cadastro: Cadastro) => dispatch(cadastroCreators.concluirSegundaEtapa(cadastro)),
    pesquisaCep: (cep: string) => dispatch(cadastroCreators.pesquisaCep(cep)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStep2Screen);

const Page = styled.View`
flex: 1;
justify-content: center;
background-color: ${primaryColorDefault};
`

const Form = styled.View`
flex: 1;
padding: 20px 10px 10px;
`

const RowContainer = styled.View`
flex-direction: row;
align-items: center;
`