import React, {useEffect, useState} from 'react';
import FloatActionButton from "../../components/FloatActionButton";
import {State} from "../../store/reducers";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ListItem} from "react-native-elements";
import styled from "styled-components/native";
import {FlatList, TouchableOpacity} from "react-native";
import {apiDataCreators} from "../../store/reducers/api_data_reducer";
import {produtoCreators} from "../../store/reducers/produto_reducer";
import Produto from "../../models/produto.model";
import {formataValor} from "../../util/format_util";
import {Toolbar} from 'react-native-material-ui';
import { FontAwesome5 } from '@expo/vector-icons';

const getSmallFoto = (urlFoto: any) => {
    if (typeof urlFoto !== 'string') return urlFoto

    return urlFoto.replace('large', 'small')
}

const ProdutosScreen = ({navigation, route, loadProdutos, produtos, editar, primaryColor, novo}) => {

    useEffect(() => {
        loadProdutos(Number(route.params.categoriaId))
    }, [route])

    const [data, setData] = useState<Produto[]>(produtos || [])

    useEffect(() =>{
        setData(produtos)
    }, [produtos])

    const renderItem = ({item}: { item: Produto }) => {
        return (
            <Item onPress={() => editar(item)}>
                <ListItem title={item.nome}
                          subtitle={`valor: R$ ${formataValor(item.valorVenda)}`}
                          leftAvatar={{source: {uri: getSmallFoto(item.foto)}}}
                          bottomDivider
                          rightIcon={<FontAwesome5 name="caret-right" size={24} color={primaryColor} />}
                />
            </Item>
        )
    }

    return (
        <>
                <Toolbar
                    leftElement="arrow-back"
                    centerElement="Produtos"
                    searchable={{
                        autoFocus: true,
                        placeholder: 'Pesquisar',
                        onChangeText: (text) => {
                            setData(prev => {
                                return produtos.filter((produto: Produto) => {
                                    return produto.nome.toLowerCase().includes(text.toLowerCase())
                                })
                            })
                        },
                    }}
                    onLeftElementPress={() => navigation.goBack()}
                    style={{background: primaryColor}}
                />
            <Page>
                <FlatList data={data} renderItem={renderItem} keyExtractor={item => item.id.toString()}/>
                <FloatActionButton onPress={novo}/>
            </Page>

        </>
    );
};

ProdutosScreen.routeName = 'catalogo/produtos'

const mapStateToProps = (state: State) => {
    return {
        produtos: state.apiData.produtos,
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor || 'rgb(84, 156, 206)',
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadProdutos: (idCategoria: number) => dispatch(apiDataCreators.listarProduto(idCategoria)),
    editar: (produto: Produto) => dispatch(produtoCreators.editarProduto(produto)),
    novo: () => dispatch(produtoCreators.novoProduto()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProdutosScreen);

const Page = styled.View`
flex: 1;
`

const Item = styled(TouchableOpacity)`
width: 100%;
`
