import React, {useEffect} from 'react';
import {Image, ScrollView, StyleSheet, TouchableOpacity, View} from "react-native";
import styled from "styled-components/native";
import * as ImagePicker from 'expo-image-picker';
import {Controller, useForm} from "react-hook-form";
import Form from "../../layout/Form";
import BaseScreen from "../../layout/BaseScreen";
import InputElement from "../../components/InputElement";
import {secundaryColor} from "../../styles/variables";
import ButtonElement from "../../components/ButtonElement";
import {State} from "../../store/reducers";
import Configuracao from "../../models/configuracao.model";
import {Dispatch} from "redux";
import {configuracaoCreators} from "../../store/reducers/configuracao_reducer";
import {connect} from "react-redux";
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from "react-native-responsive-screen";
import MaxWidth from "../../layout/MaxWidth";
import {currentPlatform} from "../../util/platform";

const defaultLogo = require('../../../assets/cam.png')

interface PropTypes {
    navigation: Function,
    configuracao: Configuracao,
    salvar: Function,
    changeLogo: Function,
}

const DetalhesScreen = ({navigation, configuracao, salvar, changeLogo}: PropTypes) => {

    const handleSelectedLogo = (pickerResult) => {
        changeLogo(pickerResult.base64 ? 'data:image/png;base64,' + pickerResult.base64 : pickerResult.uri)
    }

    let openImagePickerAsync = async () => {
        let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();

        if (permissionResult.granted === false) {
            alert("Permission to access camera roll is required!");
            return;
        }

        let pickerResult: any = await ImagePicker.launchImageLibraryAsync({
            base64: true,
        });
        handleSelectedLogo(pickerResult)
    }
    const {control, handleSubmit, reset}: any = useForm()

    useEffect(() => {
        reset({
            enderecoLoja: configuracao?.enderecoLoja,
            whattsAppLoja: configuracao?.whattsAppLoja,
        })
    }, [configuracao])

    const onSubmit = data => {
        salvar(data)
    }

    return (
        <ScrollView>
            <BaseScreen>
                <View style={styles.avatarContainer}>
                    <TouchableOpacity onPress={openImagePickerAsync}>
                        {
                            configuracao?.idWebTemplate?.logo ?
                                <Image style={{height: hp('40%'), width: wp('100%'), resizeMode: 'stretch', maxWidth: 512, maxHeight: 512, alignSelf: 'center', marginRight: currentPlatform.isWeb() ? 600 : 0}} source={{
                                    uri: configuracao.idWebTemplate.logo.replace('original', '512'),
                                }}/> : <>
                                    <View style={{marginRight: currentPlatform.isWeb() ? 600 : 0}}>
                                    <Image source={defaultLogo} style={{width: 100, height: 100, marginLeft: 100,}}/>
                                    <TextDefaultLogo/>
                                    </View>
                                </>
                        }
                    </TouchableOpacity>
                </View>
                <Form>
                    <Controller control={control} label={'Endereco'} placeholder={'digite o endereco da loja'} as={InputElement} name="enderecoLoja"
                                defaultValue={''}/>
                    <Controller control={control} label={'Numero do whatsapp'} placeholder={'(99) 99999-9999'} as={InputElement} name="whattsAppLoja"
                                defaultValue={''}/>
                    <View style={{marginBottom: 20}}>
                        <SubmmitButton onPress={handleSubmit(onSubmit)} title={'Salvar'}/>
                    </View>
                </Form>
            </BaseScreen>
        </ScrollView>
    );
};

DetalhesScreen.routeName = 'loja/detalhes'

const mapStateToProps = (state: State) => {
    return {
        configuracao: state.configuracao.configuracao,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    salvar: (configuracao: Configuracao) => dispatch(configuracaoCreators.salvarConfiguracao(configuracao)),
    changeLogo: (base64: string) => dispatch(configuracaoCreators.uploadLogo(base64)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DetalhesScreen);

const TextDefaultLogo = styled.Text.attrs(() => ({children: 'Coloque a logo da sua empresa aqui!'}))`
margin-top: 10px;
font-size: 18px;
text-align: center;
`

const SubmmitButton = styled(ButtonElement)`
margin: 20px 0;
`

const styles = StyleSheet.create({
    avatarContainer: {
        width: wp('100%'),
        height: hp('40%'),
        alignSelf: "flex-start",
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
})

const LabelText = styled.Text`
margin-bottom: 30px;
color: ${secundaryColor};
font-size: 20px;
`
