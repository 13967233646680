import Action from "../../models/action.model";
import Categoria from "../../models/categoria.model";

export const CATEGORIA_TYPES = {
    CATEGORIA_SALVAR: 'categoria/SALVAR',
    CATEGORIA_SALVAR_SUCCESS: 'categoria/SALVAR_SUCCESS',
    CATEGORIA_OPEN_MODAL: 'categoria/OPEN_MODAL',
    CATEGORIA_CLOSE_MODAL: 'categoria/CLOSE_MODAL',
}

export interface CategoriaType {
    categoria: Categoria
    modalCategoriaVisible: boolean
}

export const INITIAL_STATE: CategoriaType = {
    categoria: null,
    modalCategoriaVisible: false,
}

export const categoriaCreators = {
    salvarCategoria: (categoria: Categoria) => ({type: CATEGORIA_TYPES.CATEGORIA_SALVAR, payload: categoria}),
    salvarCategoriaSuccess: () => ({type: CATEGORIA_TYPES.CATEGORIA_SALVAR_SUCCESS}),
    abrirModal: () => ({type: CATEGORIA_TYPES.CATEGORIA_OPEN_MODAL}),
    fecharModal: () => ({type: CATEGORIA_TYPES.CATEGORIA_CLOSE_MODAL}),
}


export const categoria = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case CATEGORIA_TYPES.CATEGORIA_OPEN_MODAL:
            return {...state, modalCategoriaVisible: true}
        case CATEGORIA_TYPES.CATEGORIA_CLOSE_MODAL:
            return {...state, modalCategoriaVisible: false}
        default:
            return state
    }
}