import React from 'react';
import styled from "styled-components/native";
import {currentPlatform} from "../util/platform";

const MaxWidth = ({children}) => {
    return currentPlatform.isWeb() ? (
        <MaxWidthContainer>
            <MaxWithSubContainer>
                {children}
            </MaxWithSubContainer>
        </MaxWidthContainer>
    ) : children;
};

export default MaxWidth;

const MaxWidthContainer = styled.View`
flex: 1;
flex-direction: row;
justify-content: center;
`

const MaxWithSubContainer = styled.View`
width: 100%;
max-width: 800px;
overflow: hidden;
`
