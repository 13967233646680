import React from 'react';
import RoutesConfig from "./src/config/RoutesConfig";
import {Provider} from "react-redux";
import store from './src/store'
import {currentPlatform} from "./src/util/platform";
import {PersistGate} from 'redux-persist/integration/react'
import AsyncStorage from "@react-native-community/async-storage";
import api from "./src/config/Network";
import MaterialUIConfig from "./src/config/MaterialUIConfig";
import ErrorModal from "./src/components/ErrorModal";


if (__DEV__) {
    console.log(currentPlatform.isWeb())
    import('./src/config/ReactotronConfig').then(() => console.log('Reactotron Configured'))
}

// SE ESSA CONFIGURACAO FICAR NO ARQUIVO SRC/CONFIG/NETWORK.TS EU VOU TER ERRO DE DEPENDENCIA CICLICA DEVIDO A IMPORTACAO DA STORE
api.axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    if (error.response.status === 401) {
        await AsyncStorage.clear()
        store.store.dispatch({type: 'LOGOUT'})
    }
    return await Promise.reject(error)
})


export default function App() {
    return (
        <Provider store={store.store}>
            <PersistGate loading={null} persistor={store.persistor}>
                <MaterialUIConfig>
                    <RoutesConfig />
                    {/*<Loader />*/}
                    <ErrorModal />
                </MaterialUIConfig>
            </PersistGate>
        </Provider>
    );
}
