import { combineReducers } from "redux"
import {apiData, ApiDataType, INITIAL_STATE as apiDataInitialState} from "./api_data_reducer";
import {produto, ProdutoType, INITIAL_STATE as produtoInitialState} from "./produto_reducer";
import {usuario, UsuarioType, INITIAL_STATE as usuarioInitialState} from "./usuario_reducer";
import {categoria, CategoriaType, INITIAL_STATE as categoriaInitialState} from "./categoria_reducer";
import {configuracao, ConfiguracaoType, INITIAL_STATE as configuracaoInitialState} from "./configuracao_reducer";
import {error, ErrorType, INITIAL_STATE as errorInitialState} from "./error_reducer";
import {cadastro, CadastroType, INITIAL_STATE as cadastroInitialState} from "./cadastro_reducer";

export interface State {
    apiData: ApiDataType,
    produto: ProdutoType,
    usuario: UsuarioType,
    categoria: CategoriaType,
    configuracao: ConfiguracaoType,
    error: ErrorType,
    cadastro: CadastroType,
}

const appReducer = combineReducers({
    apiData,
    produto,
    usuario,
    categoria,
    configuracao,
    error,
    cadastro,
})

const rootReducer = (state, action) => {
    if(action.type === 'LOGOUT') return {
        ...configuracaoInitialState,
        ...usuarioInitialState,
        ...apiDataInitialState,
        ...produtoInitialState,
        ...categoriaInitialState,
        ...errorInitialState,
        ...cadastroInitialState,
    }

    return appReducer(state, action)
}

export default rootReducer

