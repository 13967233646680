import {all, takeEvery, call, put, select} from "redux-saga/effects";
import {CADASTRO_TYPES, cadastroCreators} from "../reducers/cadastro_reducer";
import api from "../../config/Network";
import {Revenda} from "../../models/revenda.model";
import {lowerAll} from "../../util/transform_case";
import Action from "../../models/action.model";
import {Cadastro} from "../../models/cadastro.model";
import {navigationRef} from "../../config/RoutesConfig";
import RegisterStep2Screen from "../../screens/auth/RegisterStep2Screen";
import RegisterStep3Screen from "../../screens/auth/RegisterStep3Screen";
import LoginScreen from "../../screens/auth/LoginScreen";
import {errorCreators} from "../reducers/error_reducer";
import {State} from "../reducers";
import {toNumber} from "../../util/math_util";
import axios from 'axios'


function* listarRevendas() {
    try {
        const {data} = yield call(api.get, 'auth/revenda')
        const revendas: Revenda[] = lowerAll(data.value)
        yield put(cadastroCreators.listarRevendasSuccess(revendas))
    } catch (e) {
        console.log(e)
    }
}

function* concluirPrimeiraEtapa({payload}: Action) {
    try {
        if(payload.cpfCnpj.length === 14) {
            const request = (cnpj: string) => axios.get(`https://bpoymh2e3b.execute-api.us-east-1.amazonaws.com/prod/consulta-cnpj/${cnpj}`)
            const {data, status} = yield call(request, payload.cpfCnpj)
            if(status >= 200 && status < 299) {
                const cadastro: Cadastro = {
                    razaoSocial: data?.nome,
                    cep: data?.cep.replace(/[^\d]/g, ''),
                    cidade: data?.municipio,
                    uf: data?.uf,
                    endereco: data?.logradouro,
                    numero: data?.numero,
                    bairro: data?.bairro,
                }
                yield put(cadastroCreators.buscaInformacoesEmpresaSuccess(cadastro))
            }
        }
        yield navigationRef.current?.navigate(RegisterStep2Screen.routeName)
    } catch (e) {
        console.log(e)
    }
}

function* pesquisaCep({payload}: Action) {
    try {
            const request = (cep: string) => axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            const {data, status} = yield call(request, payload)
            if(status >= 200 && status < 299) {
                const cadastro: Cadastro = {
                    cidade: data?.localidade,
                    uf: data?.uf,
                    endereco: data?.logradouro,
                    bairro: data?.bairro,
                }
                yield put(cadastroCreators.pesquisaCepSuccess(cadastro))
        }
    } catch (e) {
        console.log(e)
    }
}

function* concluirSegundaEtapa() {
    yield navigationRef.current?.navigate(RegisterStep3Screen.routeName)
}

function* concluirTerceiraEtapa() {
    try {
        const cadastro: Cadastro = yield select((state: State) => state.cadastro.cadastroForm)

        const cadastroSalvar = {
            RAZAO_SOCIAL: cadastro.razaoSocial,
            CPF_CNPJ: cadastro.cpfCnpj,
            CONTATO: cadastro.contato,
            EMAIL: cadastro.email,
            SENHA_MASTER: cadastro.senhaMaster,
            CELULAR: cadastro.celular,
            CEP: cadastro.cep,
            ENDERECO: cadastro.endereco,
            NUMERO: cadastro.numero,
            BAIRRO: cadastro.bairro,
            UF: cadastro.uf,
            CIDADE: cadastro.cidade,
            ID_REVENDA: toNumber(cadastro.idRevenda),
        }

        const {status, data} = yield call(api.post, '/implante/Liberador/emp/ImplantaEmpresa', cadastroSalvar)
        if(status > 299) {
            yield put(errorCreators.putError(data?.error?.message))
            throw new Error(data?.error?.message)
        }
        yield put(cadastroCreators.cadastroConcluido())
    } catch (e) {
        console.log(e)
    }
}

function* cadastroConcluido() {
    yield navigationRef.current?.navigate(LoginScreen.routeName)
}

export function* cadastroSaga(): any {
    yield all([
        takeEvery(CADASTRO_TYPES.LISTAR_REVENDAS, listarRevendas),
        takeEvery(CADASTRO_TYPES.CONCLUIR_PRIMEIRA_ETAPA, concluirPrimeiraEtapa),
        takeEvery(CADASTRO_TYPES.PESQUISA_CEP, pesquisaCep),
        takeEvery(CADASTRO_TYPES.CONCLUIR_SEGUNDA_ETAPA, concluirSegundaEtapa),
        takeEvery(CADASTRO_TYPES.CONCLUIR_TERCEIRA_ETAPA, concluirTerceiraEtapa),
        takeEvery(CADASTRO_TYPES.CONCLUIDO, cadastroConcluido),
    ])
}