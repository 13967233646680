import React, {useEffect, useState} from 'react';
import styled from "styled-components/native";
import {State} from "../../store/reducers";
import Categoria from "../../models/categoria.model";
import {ListItem} from "react-native-elements";
import {connect} from "react-redux";
import {FlatList, TouchableOpacity} from "react-native";
import {Dispatch} from "redux";
import {produtoCreators} from "../../store/reducers/produto_reducer";
import FloatActionButton from "../../components/FloatActionButton";
import {currentPlatform} from "../../util/platform";
import {ModalMobileCategoria, ModalWebCategoria} from "./ModalCategoria";
import {categoriaCreators} from "../../store/reducers/categoria_reducer";
import {apiDataCreators} from "../../store/reducers/api_data_reducer";
import Produto from "../../models/produto.model";

interface PropsType {
    categorias: Categoria[],
    navigation: any
    defineCategoria: any
    abrirModal: Function
    fecharModal: Function
    modalVisible: boolean
    salvar: Function
    listarCategorias: Function
}

const CatalogoScreen = ({categorias, defineCategoria, abrirModal, fecharModal, modalVisible, salvar, listarCategorias}: PropsType) => {

    useEffect(() => {
        listarCategorias()
    }, [])

    const handleSave = (categoria: Categoria) => {
        salvar(categoria)
    }

    const renderItem = ({item: categoria}: {item: Categoria}) => {
        return (
            <Item key={categoria.id} onPress={() => defineCategoria(categoria.id)}>
                <ListItem title={categoria.nome} subtitle={''}
                          bottomDivider/>
            </Item>
        )
    }

    return (
        <>
            <Page>
                <FlatList data={categorias} renderItem={renderItem} keyExtractor={item => item.id.toString()} />
            </Page>

            {
                currentPlatform.isWeb() ?
                    <ModalWebCategoria visible={modalVisible} closeModal={fecharModal} salvar={handleSave}/>
                    : <ModalMobileCategoria visible={modalVisible} closeModal={fecharModal} salvar={handleSave}/>
            }

            <FloatActionButton onPress={abrirModal} />
        </>
    )

};

CatalogoScreen.routeName = 'catalogo'

const mapStateToProps = (state: State) => {
    return {
        categorias: state?.apiData?.categorias,
        modalVisible: state?.categoria?.modalCategoriaVisible,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    defineCategoria: (categoriaId: number) => dispatch(produtoCreators.defineCategoria(categoriaId)),
    abrirModal: () => dispatch(categoriaCreators.abrirModal()),
    fecharModal: () => dispatch(categoriaCreators.fecharModal()),
    salvar: (categoria: Categoria) => dispatch(categoriaCreators.salvarCategoria(categoria)),
    listarCategorias: () => dispatch(apiDataCreators.listarCategoria())
})

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoScreen);

const Page = styled.View`
flex: 1;
`

const Item = styled(TouchableOpacity)`
width: 100%;
`
