import Reactotron from 'reactotron-react-js'
import {reactotronRedux} from "reactotron-redux";
import sagaPlugin from 'reactotron-redux-saga'
import apisaucePlugin from 'reactotron-apisauce'

declare global {
    interface Console {
        tron: any;
    }
}

interface PluginConfig {
    except?: string[];
}

const reactotron = Reactotron
        .configure()
        .use(reactotronRedux())
        .use(sagaPlugin({ except: [''] }))
        // .use(apisaucePlugin())
        .connect()


export default reactotron