import React from 'react';
import styled from "styled-components/native";

const BaseScreen = ({children, ...props}: any) => {
    return (
        <BaseScreenStyled {...props}>
            {children}
        </BaseScreenStyled>
    );
};

export default BaseScreen;

const BaseScreenStyled: any = styled.View`
flex: 1;
align-items: center;
`
