import React from 'react';
import {Text, View} from "react-native";
import {Dialog, DialogDefaultActions} from 'react-native-material-ui';
import {State} from "../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import styled from "styled-components/native";
import {errorCreators} from "../store/reducers/error_reducer";

const ErrorModal = ({errorMessage, closeModal}) => {
    return (
        <DialogContainer isVisible={errorMessage}>
            <Dialog>
                <Dialog.Title><Text>Erro</Text></Dialog.Title>
                <Dialog.Content>
                    <Text>
                        {errorMessage}
                    </Text>
                </Dialog.Content>
                <Dialog.Actions>
                    <DialogDefaultActions
                        actions={['ok']}
                        onActionPress={closeModal}
                    />
                </Dialog.Actions>
            </Dialog>
        </DialogContainer>
    );
};

const mapStateToProps = (state: State) => {
    return {
        errorMessage: state?.error?.erro,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(errorCreators.clearError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);


const DialogContainer = styled.View`
position: absolute;
left: 0;
right: 0;
justify-content: center;
align-items: center;
background-color: rgba(0,0,0,.4);
${({isVisible}: any) => isVisible ? ({
    flex: 1,
    width: '100%',
    height: '100%',
    zIndex: 1000,
}) : ({
    flex: 0,
    width: 0,
    height: 0,
    zIndex: 0,
    overflow: 'hidden',
})};
`