import React from 'react';
import {Controller, useForm} from "react-hook-form";
import InputElement from "../../components/InputElement";
import {AntDesign} from "@expo/vector-icons";
import { FontAwesome } from '@expo/vector-icons';
import {Button} from "react-native-elements";
import {widthPercentageToDP as wp} from "react-native-responsive-screen";
import styled from "styled-components/native";
import {primaryColorDefault} from "../../styles/variables";
import {Dispatch} from "redux";
import {cadastroCreators} from "../../store/reducers/cadastro_reducer";
import {Cadastro} from "../../models/cadastro.model";
import {connect} from "react-redux";

const RegisterStep1Screen = ({navigation, concluir}) => {

    const {control, handleSubmit, reset}: any = useForm()

    const onSubmit = data => {
        concluir(data)
    }

    return (
        <Page>
            <Controller control={control} label={'Cpf/Cnpj'} as={InputElement} name="cpfCnpj" defaultValue={''} leftIcon={<FontAwesome name="drivers-license-o" size={24} color="white" />} whiteColor={true} />
            <Controller control={control} label={'E-mail'} as={InputElement} name="email" defaultValue={''} leftIcon={<AntDesign name="mail" size={24} color="white" />} whiteColor={true}/>
            <Controller control={control} label={'Contato'} as={InputElement} name="contato" defaultValue={''} leftIcon={<AntDesign name="user" size={24} color="white" />} whiteColor={true}/>
            <Controller control={control} label={'Celular'} as={InputElement} name="celular" defaultValue={''} leftIcon={<AntDesign name="phone" size={24} color="white" />} whiteColor={true}/>
            <Button title={'AVANCAR'} onPress={handleSubmit(onSubmit)} containerStyle={{width: wp('90%')}} />
        </Page>
    );
};

RegisterStep1Screen.routeName = 'register/step-1'

const mapDispatchToProps = (dispatch: Dispatch) => ({
    concluir: (cadastro: Cadastro) => dispatch(cadastroCreators.concluirPrimeiraEtapa(cadastro)),
})

export default connect(null, mapDispatchToProps)(RegisterStep1Screen)

const Page = styled.View`
flex: 1;
justify-content: center;
align-items: center;
background-color: ${primaryColorDefault};
`

const TextCadastrar = styled.Text`
color: #fff;
text-decoration: underline;
margin-top: 10px;
`