import React from 'react';
import InputElement from "./InputElement";

const InputMonetario = ({onChange, ...props}) => {

    const handleChange = (value: string) => {
        onChange(value.replace(/[^\d.,]/g, ''))
    }

    return (
       <InputElement {...props} keyboardType={'numeric'} onChange={handleChange} />
    );
};

export default InputMonetario;