import React from 'react';
import {FlatList, TouchableOpacity} from "react-native";
import {ListItem} from "react-native-elements";
import MaxWidth from "../../layout/MaxWidth";
import styled from "styled-components/native";
import {primaryColorDefault} from "../../styles/variables";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {usuarioCreators} from "../../store/reducers/usuario_reducer";
import {connect} from "react-redux";

const IntermediariaScreen = ({empresas, selecionaEmpresa}) => {

    const renderItem = ({item}: any) => {
        return (
            <Item onPress={() => selecionaEmpresa(item)}>
                <ListItem title={item.RAZAO_SOCIAL}
                          bottomDivider
                          chevron
                />
            </Item>
        )
    }

    return (
        <MaxWidth>
            <Page>
                <SelecioneEmpresaText>Selecione a Empresa</SelecioneEmpresaText>
                <Container>
                    <FlatList data={empresas} renderItem={renderItem} keyExtractor={item => item.ID.toString()}/>
                </Container>
            </Page>
        </MaxWidth>
    );
};

IntermediariaScreen.routeName = '/intermediaria'


const mapStateToProps = (state: State) => {
    return {
        empresas: state?.usuario?.empresas,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    finalizarLogin: (value) => dispatch(usuarioCreators.logarQuartaEtapa(value)),
    selecionaEmpresa: (value) => dispatch(usuarioCreators.selecionaEmpresa(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IntermediariaScreen);

const Page = styled.View`
flex: 1;
justify-content: center;
background-color: ${primaryColorDefault};
`

const Item = styled(TouchableOpacity)`
width: 100%;
padding: 0 20px;
`

const Container = styled.View`
`

const SelecioneEmpresaText = styled.Text`
color: #fff;
font-size: 20px;
text-align: center;
margin-bottom: 50px;
`
