import {all, takeEvery, call, put, select} from "redux-saga/effects";
import api from "../../config/Network";
import Action from "../../models/action.model";
import {categoriaToCamel, lowerFirstLetter, produtoToCamel} from "../../util/transform_case";
import {API_DATA_TYPES, apiDataCreators} from "../reducers/api_data_reducer";
import {State} from "../reducers";

function* listarCategorias() {
    try {
        const nomeBanco =  yield select((state: State) => state.usuario.empresaSelecionada.NOME_BANCO)

        let {data} = yield call(api.get, `/superzapp/${nomeBanco}/subcategoria`)
        data = categoriaToCamel(data.value)

        yield put(apiDataCreators.listarCategoriaSuccess(data))
    } catch (e) {
        console.log(e)
    }
}

function* listarProdutos({payload}: Action) {

    const nomeBanco =  yield select((state: State) => state.usuario.empresaSelecionada.NOME_BANCO)

    try {
        let {data} = yield call(api.get, `/superzapp/${nomeBanco}/app/produtos2?idCategoria=${payload}`)
        data = data.value.map(value => lowerFirstLetter(value))
        yield put(apiDataCreators.listarProdutoSuccess(data))
    } catch (e) {
        console.log(e)
    }
}


export function* apiDataSaga(): any {
    yield all([
        takeEvery(API_DATA_TYPES.CATEGORIA_LIST_BEGIN, listarCategorias),
        takeEvery(API_DATA_TYPES.PRODUTO_LIST_BEGIN, listarProdutos),
    ])
}
