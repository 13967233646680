import React from 'react';
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import DetalhesScreen from "./DetalhesScreen";
import PreferenciasScreen from "./Preferencias";
import { MaterialIcons } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
import EntregaScreen from "./EntregaScreen";
import {connect} from "react-redux";
import {State} from "../../store/reducers";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import SecurityScreen from "./SecurityScreen";

const LojaScreen = ({primaryColor}) => {

    const Tab = createBottomTabNavigator()

    return (
        <Tab.Navigator tabBarOptions={{
            activeTintColor: primaryColor,
        }}>
            <Tab.Screen name={DetalhesScreen.routeName} component={DetalhesScreen} options={{
                tabBarLabel: 'Detalhes',
                tabBarIcon: (props) => <MaterialIcons name="business" size={24} color={props.color} />,
            }} />
            <Tab.Screen name={PreferenciasScreen.routeName} component={PreferenciasScreen} options={{
                tabBarLabel: 'Preferencias',
                tabBarIcon: (props) => <MaterialCommunityIcons name="cogs" size={24} color={props.color} />,
            }}/>
            <Tab.Screen name={EntregaScreen.routeName} component={EntregaScreen} options={{
                tabBarLabel: 'Entrega',
                tabBarIcon: (props) => <Octicons name="package" size={24} color={props.color} />,
            }}/>
            <Tab.Screen name={SecurityScreen.routeName} component={SecurityScreen} options={{
                tabBarLabel: 'Definições',
                tabBarIcon: (props) => <MaterialIcons name="security" size={24} color={props.color} />,
            }}/>
        </Tab.Navigator>
    );
};

LojaScreen.routeName = 'loja'

const mapStateToProps = (state: State) => {
    return {
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate.cor,
    }
}

export default connect(mapStateToProps, null)(LojaScreen);
