import {all} from 'redux-saga/effects'
import {apiDataSaga} from "./api_data_saga";
import {cadastroProdutoSaga} from "./cadastro_produto_saga";
import {usuarioSaga} from "./usuario_saga";
import {categoriaSaga} from "./categoria_saga";
import {configuracaoSaga} from "./configuracao_saga";
import {cadastroSaga} from "./cadastro_saga";


function* rootSaga() {
    yield all([
        ...apiDataSaga(),
        ...cadastroProdutoSaga(),
        ...usuarioSaga(),
        ...categoriaSaga(),
        ...configuracaoSaga(),
        ...cadastroSaga(),
    ])
}

export default rootSaga
