import React, {useEffect} from 'react';
import {createStackNavigator, CardStyleInterpolators} from "@react-navigation/stack";
import ProdutoCadastroStep1 from "./ProdutoCadastroStep1";
import ProdutoCadastroStep2 from "./ProdutoCadastroStep2";
import ProdutoCadastroStep3 from "./ProdutoCadastroStep3";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import {connect} from "react-redux";

const ProdutoCadastroScreen = ({produto, navigation}) => {

    const Stack = createStackNavigator()

    useEffect(() => {
        console.log(produto)
        if(produto && produto.id) {
            navigation.navigate(ProdutoCadastroStep2.routeName)
        }
    }, [])

    return (
        <Stack.Navigator screenOptions={{
            animationEnabled: true,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        }}>
            <Stack.Screen name={ProdutoCadastroStep1.routeName} component={ProdutoCadastroStep1} />
            <Stack.Screen name={ProdutoCadastroStep2.routeName} component={ProdutoCadastroStep2} />
            <Stack.Screen name={ProdutoCadastroStep3.routeName} component={ProdutoCadastroStep3} />
        </Stack.Navigator>
    );
};

ProdutoCadastroScreen.routeName = 'produto-cadastro'

const mapStateToProps = (state: State) => {
    return {
        produto: state?.produto?.produto,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoCadastroScreen);




