import React, {useEffect} from 'react';
import {ScrollView, View} from "react-native";
import BaseScreen from "../../layout/BaseScreen";
import Form from "../../layout/Form";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components/native";
import SwitchElement from "../../components/SwitchElement";
import {State} from "../../store/reducers";
import {Dispatch} from "redux";
import Configuracao from "../../models/configuracao.model";
import {configuracaoCreators} from "../../store/reducers/configuracao_reducer";
import {connect} from "react-redux";
import ButtonElement from "../../components/ButtonElement";
import {heightPercentageToDP, widthPercentageToDP} from "react-native-responsive-screen";

const PreferenciasScreen = ({save, configuracao}) => {

    const {control, handleSubmit, reset}: any = useForm()

    const onSubmit = data => {
        save(data)
    }

    useEffect(() => {
        reset({
            obsPedido: configuracao?.obsPedido,
            exibePrecoPedido: configuracao?.exibePrecoPedido,
            statusLoja: configuracao?.statusLoja,
            enviarWhattsApp: configuracao?.enviarWhattsApp,
        })
    }, [configuracao])

    return (
            <Page>
                <FormStyled>
                    <Controller control={control} label={'Obs pedido'} as={SwitchElementComMargem} name="obsPedido" defaultValue={false}/>
                    <Controller control={control} label={'Exibe preco pedido'} as={SwitchElementComMargem} name="exibePrecoPedido" defaultValue={false}/>
                    <Controller control={control} label={'Status loja'} as={SwitchElementComMargem} name="statusLoja" defaultValue={false}/>
                    <Controller control={control} label={'Enviar whatsapp'} as={SwitchElementComMargem} name="enviarWhattsApp" defaultValue={false}/>
                </FormStyled>
                <ButtonContainer>
                    <SubmmitButton onPress={handleSubmit(onSubmit)} title={'Salvar'} />
                </ButtonContainer>
            </Page>
    );
};

const mapStateToProps = (state: State) => {
    return {
        configuracao: state.configuracao.configuracao,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    save: (configuracao: Configuracao) => dispatch(configuracaoCreators.salvarConfiguracao(configuracao)),
})

PreferenciasScreen.routeName = 'loja/preferencias'

export default connect(mapStateToProps, mapDispatchToProps)(PreferenciasScreen);

const SwitchElementComMargem = styled(SwitchElement)`
margin-bottom: 20px;
`

const SubmmitButton = styled(ButtonElement)`
margin: 20px 0;
width: 600px;
`

const Page = styled.View`
align-items: center;
justify-content: space-between;
height: 100%;
`

const FormStyled: any = styled(Form)`
justify-content: space-between;
`

const ButtonContainer = styled.View`
margin-bottom: 20px;
width: 100%;
max-width: 350px;
`
