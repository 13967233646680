import {all, call, put, takeEvery} from "redux-saga/effects";
import api from "../../config/Network";
import Action from "../../models/action.model";
import {CATEGORIA_TYPES, categoriaCreators} from "../reducers/categoria_reducer";
import {apiDataCreators} from "../reducers/api_data_reducer";

function* salvarCategoria({payload}: Action) {
    try {
        const reqBody = {
            Id: payload.id,
            Nome: payload.nome,
        }

        let {data} = yield call(api.post, '/cad/toqweb/subcategoriainterface/gravarsubcategoria', reqBody)

        yield put(categoriaCreators.salvarCategoriaSuccess())

    } catch (e) {
        console.log(e)
    }
}

function* categoriaSalva() {
    yield put(categoriaCreators.fecharModal())
    yield put(apiDataCreators.listarCategoria())
}

export function* categoriaSaga(): any {
    yield all([
        takeEvery(CATEGORIA_TYPES.CATEGORIA_SALVAR, salvarCategoria),
        takeEvery(CATEGORIA_TYPES.CATEGORIA_SALVAR_SUCCESS, categoriaSalva),
    ])
}
