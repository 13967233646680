import React, {createRef} from 'react';
import {DefaultTheme, DrawerActions, NavigationContainer} from '@react-navigation/native';
import {CardStyleInterpolators, createStackNavigator} from "@react-navigation/stack";
import HomeScreen from "../screens/HomeScreen";
import {Ionicons} from '@expo/vector-icons';
import styled from "styled-components/native";
import CameraScreen from "../screens/CameraScreen";
import ProdutoCadastroScreen from "../screens/catalogo/ProdutoCadastroScreen";
import ProdutosScreen from "../screens/catalogo/ProdutosScreen";
import LoginScreen from "../screens/auth/LoginScreen";
import RegisterScreen from "../screens/auth/RegisterScreen";
import {State} from "../store/reducers";
import {connect} from "react-redux";
import {SafeAreaView, StatusBar} from "react-native";
import IntermediariaScreen from "../screens/auth/IntermediariaScreen";
import MaxWidth from "../layout/MaxWidth";

export const navigationRef: any = createRef()


const RoutesConfig = ({isLogged, primaryColor}) => {


    const MenuIcon = styled.View.attrs(() => ({
        children: <Ionicons name="ios-menu" size={35} color={'#fff'} onPress={() => {
            navigationRef.current.dispatch(DrawerActions.toggleDrawer)
        }}/>,
    }))`
margin-left: 20px;
`

    const headerStyle = {
        headerStyle: {
            backgroundColor: primaryColor,
        },
        headerTintColor: '#fff',
    }

    const Stack = createStackNavigator()

    return (
        <MaxWidth>
        <NavigationContainer ref={navigationRef}>
            <StatusBar barStyle="light-content" backgroundColor={primaryColor} />
            {
                isLogged ?
                    <Stack.Navigator>
                        <Stack.Screen name={HomeScreen.routeName} component={HomeScreen} options={{
                            title: 'SuperZapp Painel',
                            ...headerStyle,
                            headerLeft: (props) => <MenuIcon/>,
                        }}/>
                        <Stack.Screen name={CameraScreen.routeName} component={CameraScreen}
                                      options={{headerShown: false}}/>
                        <Stack.Screen name={ProdutosScreen.routeName} component={ProdutosScreen} options={{
                            // title: 'Produtos',
                            // ...headerStyle,
                            headerShown: false,
                        }}/>
                        <Stack.Screen name={ProdutoCadastroScreen.routeName} component={ProdutoCadastroScreen}
                                      options={{
                                          title: 'Novo Produto',
                                          ...headerStyle,
                                      }}/>
                    </Stack.Navigator>
                    :
                    <Stack.Navigator screenOptions={{
                        animationEnabled: true,
                        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                    }}>
                        <Stack.Screen name={LoginScreen.routeName} component={LoginScreen} options={{headerShown: false}} />
                        <Stack.Screen name={IntermediariaScreen.routeName} component={IntermediariaScreen} options={{headerShown: false}} />
                        <Stack.Screen name={RegisterScreen.routeName} component={RegisterScreen} options={{title: 'Cadastrar', ...headerStyle}} />
                    </Stack.Navigator>
            }

         </NavigationContainer>
        </MaxWidth>
    );
};

const mapStateToProps = (state: State) => {
    return {
        isLogged: !!state?.usuario?.token,
        primaryColor: state?.configuracao?.configuracao?.idWebTemplate?.cor || 'rgb(84, 156, 206)',
    }
}

export default connect(mapStateToProps, null)(RoutesConfig);
